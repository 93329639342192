import React, {useCallback, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import animalsDB from "../../../database/animalsDB";
import groupsDB from "../../../database/groupsDB";
import {Level} from "../../../constans/levelTypes";
import buildingsDB from "../../../database/buildingsDB";
import {useDispatch, useSelector} from "react-redux";
import {getFarmID} from "../../../selectors/farmSelector";
import {changeSelectedItem} from "../../../actions/animalDocumentsAction";
import Document from "../Document";
import utilSelectors from "../../../selectors/utilSelectors";
import {isService as isServiceSelector} from "../../../selectors/userSelector";
import {getSelectedAnimalForDocuments} from "../../../selectors/animalDocumentsSelectors";

const AnimalDocument = () => {
    const isService = useSelector(isServiceSelector);
    const FarmID = useSelector(getFarmID);
    // czas modyfikacji zwierząt
    const animalsTime = useSelector(utilSelectors.getAnimalDtaModTime);
    // czas modyfikacji grup
    const groupsTime = useSelector(utilSelectors.getGroupDtaModTime);
    // aktualnie wybrane zwierzę lub grupa
    const selectedItem = useSelector(getSelectedAnimalForDocuments);

    const {search} = useLocation();

    // zwraca zwierzę lub grupę z linku
    const animalOrGroup = useMemo(() => {
        const params = new URLSearchParams(search);
        // wyszukaj zwierzę
        const AnmID = params.get("documentsAnimal");
        if (AnmID) {
            const animal = animalsDB.getAnimalById(AnmID, {
                findDeleted: true,
                joinEvents: false,
            });
            return animal ?? null;
        }
        // wyszukaj grupę
        const GroupID = params.get("documentsGroup");
        if (GroupID) {
            const group = groupsDB.findGroupByID(GroupID, {showDeleted: true});
            return group ?? null;
        }
        // wyszukaj zwierzę po RFID lub zwierzę w lokalizacji RFID
        const RFID = params.get("documentsRFID");
        if (RFID) {
            let animals = animalsDB.getAnimalsByRfid(RFID, FarmID);
            if (animals.length === 0) {
                const stand = buildingsDB.getBoxBIDByRfid(RFID);
                if (stand) {
                    animals = animalsDB.getAllAnimalsForLocation(
                        stand,
                        Level.BOX,
                        {joinEvents: false}
                    );
                }
            }
            if (animals.length === 1) {
                return animals[0] ?? null;
            }
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, isService, FarmID, animalsTime, groupsTime]);

    const dispatch = useDispatch();

    // stwórz klucz, który się zmieni, gdy zwierzę lub grupa będzie zmodyfikowana
    const makeKey = useCallback(
        (item) =>
            `${item?.AnmGrp || item?.AnmID || "#"}_${item?.DtaModTime || 0}`,
        []
    );

    // ustaw zwierzę z linku na store
    useEffect(() => {
        if (animalOrGroup && makeKey(animalOrGroup) !== makeKey(selectedItem)) {
            console.log(makeKey(animalOrGroup));
            dispatch(changeSelectedItem(animalOrGroup));
        }
    }, [dispatch, animalOrGroup, selectedItem, makeKey]);

    return animalOrGroup ? <Document /> : null;
};

export default React.memo(AnimalDocument);
