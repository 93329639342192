import React, {Component} from "react";
import Button from "../../basics/button/Button";
import {connect} from "react-redux";
import {ModalName as SowCardRemoveEventModalName} from "../../modals-new/sow-card-remove-event-modal/SowCardRemoveEventModal";
import "./_sow.card.scss";
import Card from "../../basics/card/Card";
import TextSwitch from "../../basics/text-switch/TextSwitch";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {withTranslation} from "react-i18next";
import {
    findIndex,
    findLast,
    get,
    isEmpty,
    isEqual,
    isNil,
    uniqBy,
} from "lodash";
import CycleGridHeader from "../../animal-documents/renderers/CycleGridHeader";
import * as EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import animalsDB from "../../../database/animalsDB";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import CycleNumberRenderer from "../../animal-documents/renderers/CycleNumberRenderer";
import InseminationRenderer from "../../animal-documents/renderers/InseminationRenderer";
import USGRenderer from "../../animal-documents/renderers/USGRenderer";
import ParturitionRenderer from "../../animal-documents/renderers/ParturitionRenderer";
import FallPigletsRenderer from "../../animal-documents/renderers/FallPigletsRenderer";
import MommySeparationsRenderer from "../../animal-documents/renderers/MommySeparationsRenderer";
import SeparationRenderer from "../../animal-documents/renderers/SeparationRenderer";
import ActiveNipplesRenderer from "../../animal-documents/renderers/ActiveNipplesRenderer";
import WeightingSowRenderer from "../../animal-documents/renderers/WeightingSowRenderer";
import NoPregnancyRenderer from "../../animal-documents/renderers/NoPregnancyRenderer";
import MommyRenderer from "../../animal-documents/renderers/MommyRenderer";
import ExpandRenderer from "../../animal-documents/renderers/ExpandRenderer";
import {
    getCorrectValidatedEventsData,
    getEmptyCycleRow,
} from "../../../utils/AnimalDocumentsUtils";
import {getCycleReason} from "../../../utils/CycleUtils";
import {getMamasFromTattooCycle} from "../../../utils/EventUtils";
import EditEventPopup from "./mini-components/EditEventPopup";
import {getAnimalUnit} from "../../../utils/SettingsUtils";
import {Col, Row} from "react-bootstrap";
import {isMobile} from "../../../utils/MobileUtils";
import {isOnline} from "../../../selectors/onlineSelector";
import {getShowGiltsOnParturition} from "../../../selectors/settingsSelector";
import SowCardRemoveEventModal from "../../modals-new/sow-card-remove-event-modal/SowCardRemoveEventModal";
import {getAnimalEvents} from "../../../selectors/animalDocumentsSelectors";

function mapStateToProps(state) {
    return {
        animal: state.animalDocuments.selectedItem,
        animalEvents: getAnimalEvents(state),
        cycleTable: state.animalDocuments.cycleTable,
        online: isOnline(state),
        showGilts: getShowGiltsOnParturition(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

const VIEW_TYPES = {
    BASIC: 1,
    ADVANCED: 2,
};

class SowCardGridMinimal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: VIEW_TYPES.BASIC,
            cycleData: SowCardGridMinimal.getCycleData(this.props),
            expanded: {rows: [], opened: false},
            cellRect: null,
            showPopup: false,
            updateData: {
                event: null,
                path: "",
            },
        };
        this.mobile = isMobile();
        this.table = React.createRef();
    }

    static getBoarName(BoarID) {
        const animal = animalsDB.getAnimalById(BoarID);
        return get(animal, "AnmNo1", "-");
    }

    static weightValueFormatter(value) {
        return !!value
            ? convertWeightUnitTo(value, {
                  unit: getAnimalUnit(),
                  showUnit: true,
                  fixed: 1,
              })
            : "";
    }

    static getCycleData(props) {
        const {cycleTable, animal} = props;
        const validatedEventsData = getCorrectValidatedEventsData(
            cycleTable,
            animal
        );
        return validatedEventsData.map((row, index) => {
            const castrationWeightings = get(
                row,
                EventTypes.CASTRATION,
                []
            ).filter((o) => get(o, "EvData.Weighting", false));
            const castrationOperators = castrationWeightings.map(
                (o) => o.EvData.OperID
            );
            const {subtracted, added} = getMamasFromTattooCycle(row);
            return {
                cycle: row.cycle,
                key: index,
                isInterrupted: row.isInterrupted,
                invalidEvents: get(row, "invalidEvents", []),
                inseminations: get(row, EventTypes.INSEMINATION, []).map(
                    (e) => ({
                        event: e,
                        invalidEvents: get(row, "invalidEvents", []).filter(
                            (o) => o.EvCode === EventTypes.INSEMINATION
                        ),
                        operators: get(row, EventTypes.INSEMINATION, []).map(
                            (o) => o.EvData.OperID
                        ),
                    })
                ),
                usgs: get(row, EventTypes.USG, []).map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.USG
                    ),
                    operators: get(row, EventTypes.USG, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                parturitions: get(row, EventTypes.PARTURITION, []).map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.PARTURITION
                    ),
                    operators: get(row, EventTypes.PARTURITION, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                castrations: get(row, EventTypes.CASTRATION, []).map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.CASTRATION
                    ),
                    operators: get(row, EventTypes.CASTRATION, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                separations: get(row, EventTypes.SEPARATION, []).map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.SEPARATION
                    ),
                    operators: get(row, EventTypes.SEPARATION, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                sowCycles: get(row, EventTypes.SOW_CYCLES, []).map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.SOW_CYCLES
                    ),
                    operators: get(row, EventTypes.SOW_CYCLES, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                falls: get(row, EventTypes.FALL_PIGLETS, []).map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.FALL_PIGLETS
                    ),
                    operators: get(row, EventTypes.FALL_PIGLETS, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                weightings: castrationWeightings.map((e) => ({
                    event: e,
                    invalidEvents: [],
                    operators: castrationOperators,
                })),
                separationsMommy: [
                    ...get(row, EventTypes.SEPARATION_TO_MOMMY, []),
                    ...subtracted,
                ].map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.SEPARATION_TO_MOMMY
                    ),
                    operators: get(row, EventTypes.SEPARATION_TO_MOMMY, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                mommys: [...get(row, EventTypes.MOMMY, []), ...added].map(
                    (e) => ({
                        event: e,
                        invalidEvents: get(row, "invalidEvents", []).filter(
                            (o) => [EventTypes.MOMMY].includes(o.EvCode)
                        ),
                        operators: [...get(row, EventTypes.MOMMY, [])].map(
                            (o) => o.EvData.OperID
                        ),
                    })
                ),
                noPregnancy: get(row, EventTypes.NO_PREGNANCY, []).map((e) => ({
                    event: e,
                    invalidEvents: get(row, "invalidEvents", []).filter(
                        (o) => o.EvCode === EventTypes.NO_PREGNANCY
                    ),
                    operators: get(row, EventTypes.NO_PREGNANCY, []).map(
                        (o) => o.EvData.OperID
                    ),
                })),
                activeNipples: get(row, EventTypes.ACTIVE_NIPPLES, []).map(
                    (e) => ({
                        event: e,
                        invalidEvents: get(row, "invalidEvents", []).filter(
                            (o) => o.EvCode === EventTypes.ACTIVE_NIPPLES
                        ),
                        operators: get(row, EventTypes.ACTIVE_NIPPLES, []).map(
                            (o) => o.EvData.OperID
                        ),
                    })
                ),
            };
        });
    }

    onDeletionModeButtonClick = () => {
        const {onDeletionModeChange} = this.props;
        onDeletionModeChange();
    };

    getCycleRenderClass(kind, row) {
        const invalidEventsInRow = this.getInvalidEventsInRow(kind, row);
        const invalidEventType = invalidEventsInRow.find(
            (e) => e.EvCode === kind
        );
        if (!!invalidEventType) {
            return `invalid-event ${invalidEventType.type || "error"}`;
        }
    }

    getInvalidEventsInRow(kind, row) {
        const {cycleData} = this.state;
        if (!isEmpty(cycleData)) {
            return uniqBy(
                cycleData.find((d) => d.key === row).invalidEvents,
                (el) => el.EvCode
            );
        }
        return [];
    }

    getTooltipContent(kind, row, message) {
        try {
            const invalidEventsInRow = this.getInvalidEventsInRow(
                kind,
                row
            ).filter((ev) => ev.EvCode === kind);
            if (!isEmpty(invalidEventsInRow)) {
                return (
                    <div>
                        <b>{message}</b>
                        {invalidEventsInRow.map((ev, index) => (
                            <li key={index}>
                                {getCycleReason(ev.Reason, ev.AdditionalData)}
                            </li>
                        ))}
                    </div>
                );
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    onExpandedRowClick = (value, opened) => {
        const {
            expanded: {rows},
        } = this.state;
        let _value = rows.slice(0);
        let exist = _value.filter((o) => o === value).length;
        if (exist && !opened) {
            _value = _value.filter((idx) => idx !== value);
        } else if (!exist && opened) {
            _value.push(value);
        }
        this.setState({
            expanded: {rows: _value},
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {cycleTable, animalEvents, animal} = this.props;
        if (
            !isEqual(prevProps.cycleTable, cycleTable) ||
            !isEqual(prevProps.animalEvents, animalEvents) ||
            !isEqual(prevProps.animal, animal)
        ) {
            this.setState({
                cycleData: SowCardGridMinimal.getCycleData(this.props),
            });
        }
    }

    getSwitchOptions = (props) => {
        const {t} = props;
        return [
            {
                name: t("basic"),
                value: VIEW_TYPES.BASIC,
            },
            {
                name: t("advanced"),
                value: VIEW_TYPES.ADVANCED,
            },
        ];
    };

    onSwitchView = (value) => {
        this.setState({
            view: value,
        });
    };

    onPrintClick = () => {
        const {print} = this.props;
        print();
    };

    getGridHeaders = () => {
        const {view} = this.state;
        const {t, showGilts} = this.props;
        return (
            <>
                {view === VIEW_TYPES.ADVANCED && (
                    <CycleGridHeader
                        className={"non-print-first-record"}
                        title={t("animalDocuments.expand")}
                    />
                )}
                <CycleGridHeader title={t("animalDocuments.cycleNumber")} />
                <CycleGridHeader
                    title={t("animalDocuments.insemination")}
                    columns={[
                        {name: t("date")},
                        {name: t("boar")},
                        {name: t("singleTechnologyGroupShort")},
                    ]}
                />
                <CycleGridHeader
                    title={t("usg")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("date")}]
                            : []),
                        {name: t("result")},
                    ]}
                />
                <CycleGridHeader
                    title={t("animalDocuments.birth")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("date")}]
                            : []),
                        {name: t("alive")},
                        {name: t("weakPiglets")},
                        {name: t("dead")},
                        {name: t("mummies")},
                        ...(showGilts ? [{name: t("gilts")}] : []),
                    ]}
                />
                <CycleGridHeader
                    title={t("fallPiglets")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("date")}]
                            : []),
                        {name: t("amount")},
                        {
                            name: t("age"),
                            tooltipContent: t(
                                "animalDocuments.ageFromParturition"
                            ),
                        },
                    ]}
                />
                <CycleGridHeader
                    title={t("takenPiglets")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("date")}]
                            : []),
                        {name: t("amount")},
                    ]}
                />
                <CycleGridHeader
                    title={t("addedPiglets")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("date")}]
                            : []),
                        {name: t("amount")},
                    ]}
                />
                <CycleGridHeader
                    title={t("weighting")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("animalDocuments.pigletsAge")}]
                            : []),
                        {
                            name: t("animalDocuments.avgParturitionWeight"),
                        },
                    ]}
                />
                <CycleGridHeader
                    title={t("separation")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("animalDocuments.pigletsAge")}]
                            : []),
                        {name: t("amount")},
                        {name: t("animalDocuments.avgSeparationWeight")},
                    ]}
                />
                <CycleGridHeader
                    title={t("animalDocuments.activeNipples")}
                    columns={[{name: t("amount")}]}
                    className="print-last"
                />
                <CycleGridHeader
                    title={t("noPregnancy")}
                    columns={[
                        ...(view !== VIEW_TYPES.BASIC
                            ? [{name: t("date")}]
                            : []),
                        {
                            name: t("reason"),
                            className: "last-header",
                        },
                    ]}
                    className="print-display-none last-main-header"
                />
            </>
        );
    };

    getEmptyRows = (neededRows) => {
        const {cycleData} = this.state;
        const emptyRows = [];
        for (let count = 1; count <= neededRows; count++) {
            emptyRows.push({
                shouldExpand: false,
                cycle: cycleData[cycleData.length - 1]
                    ? cycleData[cycleData.length - 1].cycle + count
                    : cycleData.length - 1 + count,
                ...getEmptyCycleRow(),
            });
        }
        return emptyRows;
    };

    onDeleteEventClick = (event) => {
        const {animalEvents, show} = this.props;
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            const selectedEvent = animalEvents.find((e) => e.EvID === EvID);
            if (selectedEvent) {
                show(SowCardRemoveEventModalName, {
                    EvID,
                });
            }
        }
    };

    onDoubleEventClick = (e) => {
        const {t} = this.props;
        if (!this.props.online) return;
        const splittedArray = get(e, "currentTarget.id", "").split(" ");
        const eventID = splittedArray[0];
        const path = splittedArray[1];
        if (eventID && path) {
            const {animalEvents, cycleTable} = this.props;
            const {
                view,
                expanded: {rows},
            } = this.state;
            let event = animalEvents.find((ev) => ev.EvID === eventID);
            if (!event) return;
            const cycleRowIndex = findIndex(cycleTable, (row) =>
                row[event.EvCode].find((ev) => ev.EvID === event.EvID)
            );
            const eventCycleRow = cycleTable[cycleRowIndex];
            if (
                path === "EvData.Weight" &&
                event.EvCode === EventTypes.PARTURITION
            ) {
                const castrationEvent = findLast(
                    get(eventCycleRow, `[${EventTypes.CASTRATION}]`, []),
                    (ca) => get(ca, "EvData.Weighting", false)
                );
                if (castrationEvent) {
                    event = castrationEvent;
                }
            }
            const label = t([
                `shadowDescription.EVENT_SHARED.${path}._title`,
                `shadowDescription.EVENT_${event.EvCode}.${path}._title`,
            ]);
            const stateObject = {
                showPopup: true,
                cellRect: null,
                updateData: {
                    event,
                    path,
                    label,
                },
            };
            let shouldOpen = false;
            if (eventCycleRow[event.EvCode].length > 1) shouldOpen = true;
            if (shouldOpen) {
                if (view === VIEW_TYPES.BASIC) {
                    this.setState({
                        view: VIEW_TYPES.ADVANCED,
                    });
                }
                const isExpanded = rows.find((n) => n === cycleRowIndex);
                if (!isExpanded) {
                    this.onExpandedRowClick(cycleRowIndex, true);
                    setTimeout(() => {
                        const el = document.getElementById(
                            `${event.EvID} ${path}`
                        );
                        if (!isNil(el)) {
                            let clientRect = el.getBoundingClientRect();
                            this.setState({
                                ...stateObject,
                                cellRect: clientRect,
                            });
                        }
                    });
                    return;
                }
            }
            const currentWrapperRect = e.currentTarget.getBoundingClientRect();
            this.setState({
                ...stateObject,
                cellRect: currentWrapperRect,
            });
        }
    };

    onHidePopupClick = () => {
        this.setState({
            showPopup: false,
        });
    };

    getPrintButton() {
        const {t, deletionMode} = this.props;
        return (
            <Button
                className="m-0"
                icon={<i className="far fa-print" />}
                id="print-button"
                buttonColor="primary"
                onClick={this.onPrintClick}
                disabled={deletionMode}>
                {t("grid.print1")}
            </Button>
        );
    }

    render() {
        const {view, cycleData, expanded, showPopup, updateData, cellRect} =
            this.state;
        const {t, online, printing, deletionMode, animal, animalEvents} =
            this.props;
        const switchOptions = this.getSwitchOptions(this.props);
        const gridHeaders = this.getGridHeaders();
        const isAdvanced = view === VIEW_TYPES.ADVANCED;
        const neededRows = cycleData.length < 6 ? 6 - cycleData.length : 0;
        const printButton = this.getPrintButton();
        return (
            <>
                <SowCardRemoveEventModal
                    animal={animal}
                    cycleData={cycleData}
                    animalEvents={animalEvents}
                />
                {showPopup && (
                    <EditEventPopup
                        animal={animal}
                        cellRect={cellRect}
                        updateData={updateData}
                        onHide={this.onHidePopupClick}
                    />
                )}
                <Card className={deletionMode ? "deletion-mode" : ""}>
                    <Row
                        className={`sow-card-header${this.mobile ? " justify-content-center" : ""} ptb-2`}>
                        <Col
                            className="d-flex align-items-center"
                            xs={this.mobile ? 12 : 6}>
                            <h5 className={`me-2${this.mobile ? " mb-2" : ""}`}>
                                {t("animalDocuments.cyclesHistory")}
                            </h5>
                            {!this.mobile && printButton}
                        </Col>
                        <Col
                            className={`d-flex flex-${this.mobile ? "column align-items-center" : "row"}`}
                            xs={this.mobile ? 12 : 6}>
                            <TextSwitch
                                className={this.mobile ? "mb-2 ms-0 w-100" : ""}
                                onChange={this.onSwitchView}
                                value={view}
                                options={switchOptions}
                            />
                            <Button
                                buttonStyle={
                                    deletionMode || this.mobile
                                        ? "text"
                                        : "round"
                                }
                                buttonColor={"error"}
                                icon={<i className={`fas fa-trash fa-fw`} />}
                                disabled={!online}
                                className={this.mobile ? "w-100" : ""}
                                onClick={this.onDeletionModeButtonClick}>
                                {deletionMode &&
                                    t("sowCardGridMinimal.deletionModeActive")}
                                {this.mobile &&
                                    !deletionMode &&
                                    t("sowCardGridMinimal.enableDeletionMode")}
                            </Button>
                        </Col>
                    </Row>
                    {this.mobile && <hr />}
                    <div className="sow-card">
                        <div className="cycle-grid-container" ref={this.table}>
                            <div className="cycle-grid">
                                {gridHeaders}
                                {cycleData.map((row, index) => (
                                    <React.Fragment key={index}>
                                        {isAdvanced && (
                                            <ExpandRenderer
                                                expanded={expanded}
                                                cycle={row}
                                                onPress={
                                                    this.onExpandedRowClick
                                                }
                                            />
                                        )}
                                        <CycleNumberRenderer
                                            expanded={expanded}
                                            cycle={row}
                                            isAdvanced={isAdvanced}
                                            printing={printing}
                                        />
                                        <InseminationRenderer
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.INSEMINATION,
                                                index
                                            )}
                                            expanded={expanded}
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            deletionMode={deletionMode}
                                            printing={printing}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.INSEMINATION,
                                                index,
                                                t(
                                                    "animalDocuments.insemination"
                                                )
                                            )}
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            isInterrupted={row.isInterrupted}
                                        />
                                        <USGRenderer
                                            expanded={expanded}
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.USG,
                                                index
                                            )}
                                            deletionMode={deletionMode}
                                            printing={printing}
                                            rowIndex={index}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            isInterrupted={row.isInterrupted}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.USG,
                                                index,
                                                "USG"
                                            )}
                                        />
                                        <ParturitionRenderer
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            printing={printing}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.PARTURITION,
                                                index,
                                                t("animalDocuments.birth")
                                            )}
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.PARTURITION,
                                                index
                                            )}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            isInterrupted={row.isInterrupted}
                                            deletionMode={deletionMode}
                                            rowIndex={index}
                                        />
                                        <FallPigletsRenderer
                                            expanded={expanded}
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            printing={printing}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.FALL_PIGLETS,
                                                index,
                                                t("fallPiglets")
                                            )}
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.FALL_PIGLETS,
                                                index
                                            )}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            isInterrupted={row.isInterrupted}
                                            deletionMode={deletionMode}
                                        />
                                        <MommySeparationsRenderer
                                            expanded={expanded}
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            printing={printing}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.SEPARATION_TO_MOMMY,
                                                index,
                                                t(
                                                    "animalDocuments.mommySeparation"
                                                )
                                            )}
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.SEPARATION_TO_MOMMY,
                                                index
                                            )}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            deletionMode={deletionMode}
                                            isInterrupted={row.isInterrupted}
                                        />
                                        <MommyRenderer
                                            expanded={expanded}
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.MOMMY,
                                                index,
                                                t("mommy")
                                            )}
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.MOMMY,
                                                index
                                            )}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            printing={printing}
                                            deletionMode={deletionMode}
                                            isInterrupted={row.isInterrupted}
                                        />
                                        <WeightingSowRenderer
                                            expanded={expanded}
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            printing={printing}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            deletionMode={deletionMode}
                                            isInterrupted={row.isInterrupted}
                                        />
                                        <SeparationRenderer
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.SEPARATION,
                                                index,
                                                t("separation")
                                            )}
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.SEPARATION,
                                                index
                                            )}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            printing={printing}
                                            rowIndex={index}
                                            deletionMode={deletionMode}
                                            expanded={expanded}
                                            isInterrupted={row.isInterrupted}
                                        />
                                        <ActiveNipplesRenderer
                                            cycle={row}
                                            tooltipContent={this.getTooltipContent(
                                                EventTypes.ACTIVE_NIPPLES,
                                                index,
                                                t(
                                                    "animalDocuments.activeNipples"
                                                )
                                            )}
                                            cycleRenderClass={this.getCycleRenderClass(
                                                EventTypes.ACTIVE_NIPPLES,
                                                index
                                            )}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            deletionMode={deletionMode}
                                            printing={printing}
                                            isInterrupted={row.isInterrupted}
                                        />
                                        <NoPregnancyRenderer
                                            isAdvanced={isAdvanced}
                                            cycle={row}
                                            onDoubleEventClick={
                                                this.onDoubleEventClick
                                            }
                                            onDeleteClick={
                                                this.onDeleteEventClick
                                            }
                                            deletionMode={deletionMode}
                                            isInterrupted={row.isInterrupted}
                                        />
                                    </React.Fragment>
                                ))}
                                {this.getEmptyRows(neededRows).map(
                                    (row, index) => (
                                        <React.Fragment key={index}>
                                            {isAdvanced && (
                                                <ExpandRenderer
                                                    expanded={expanded}
                                                    cycle={row}
                                                    onPress={
                                                        this.onExpandedRowClick
                                                    }
                                                />
                                            )}
                                            <CycleNumberRenderer cycle={row} />
                                            <InseminationRenderer
                                                expanded={expanded}
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                            <USGRenderer
                                                expanded={expanded}
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                            <ParturitionRenderer
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                            <FallPigletsRenderer
                                                expanded={expanded}
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                            <MommySeparationsRenderer
                                                expanded={expanded}
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                            <MommyRenderer
                                                expanded={expanded}
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                            <WeightingSowRenderer
                                                expanded={expanded}
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                            <SeparationRenderer
                                                isAdvanced={isAdvanced}
                                                expanded={expanded}
                                                cycle={row}
                                            />
                                            <ActiveNipplesRenderer
                                                cycle={row}
                                            />
                                            <NoPregnancyRenderer
                                                isAdvanced={isAdvanced}
                                                cycle={row}
                                            />
                                        </React.Fragment>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(SowCardGridMinimal);
