import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import {flatten, get, groupBy, memoize, isFunction} from "lodash";
import memoizeOne from "memoize-one";
import moment from "moment";
import Backup from "../beans/Backup";
import PathParser from "../components/svg-editor/utils/PathParser";
import parser from "../components/svg-editor/utils/parser";
import {
    getDistanceBetweenTwoPoints,
    getNormalizedVectorFromTwoPoints,
} from "../components/svg-editor/utils/utils";
import {UniqueEntities, UniqueEntitiesLevel} from "../constans/farmMap";
import {Level} from "../constans/levelTypes";
import {SectorType} from "../constans/sectorTypes";
import {getBuildingsMap} from "../selectors/buildingsSelector";
import store from "../store/store";
import {MAX_CANVAS_SIZE_SAFARI, clearCanvas} from "./DOMUtils";
import {
    getBiggestRectInMatrix,
    isBetween,
    isFiniteNumber,
    rotateCartesian,
} from "./MathUtils";
import {getAnimalWeightsTable} from "./SettingsUtils";
import {isIPSUM, isNutriPro, isNutriProV2, isRFID} from "./DispenserNRFUtils";
import {makeEnhancedComparer} from "./TextUtils";
import {getDiameter} from "./SiloRadarUtils";
import {Browser} from "../components/basics/browser-detection/Browser";

export const parseFarmMapFromSVG = memoize(
    (svg) => {
        try {
            let domparser = new window.DOMParser();
            let doc = domparser.parseFromString(svg, "image/svg+xml");
            console.log({x: doc.documentElement});
            return doc && doc.documentElement instanceof SVGSVGElement
                ? doc.documentElement
                : null;
        } catch (e) {
            console.warn(e);
            return null;
        }
    },
    (...args) => JSON.stringify(args)
);

export const generateItemsFromSVG = (farmMapObj) => {
    const size = {width: 1000, height: 1000};
    const levels = [];
    const version = get(farmMapObj, "SetData.Version", 0);
    if (version < Backup.VERSION[Backup.TYPE.FARM_MAP]) return {levels, size};
    const levelsObj = get(farmMapObj, "SetData.Levels", {});
    const levelKeys = Object.keys(levelsObj);
    for (let levelKey of levelKeys) {
        const items = [];
        const level = parseFarmMapFromSVG(levelsObj[levelKey]);
        if (!level) continue;
        const viewBox = level.attributes.viewBox.value
            .split(" ")
            .map(Number.parseFloat);
        size.height = Math.max(size.height, viewBox.pop());
        size.width = Math.max(size.width, viewBox.pop());
        let keys = levelKey === "bg" ? ["others"] : ["chambers", "devices"];
        for (let key of keys) {
            const nodes = get(level, `children.${key}.children`, []);
            [...nodes].forEach((node) => {
                const params = {};
                for (let j = 0; j < get(node, "attributes.length", 0); j++) {
                    const attribute = node.attributes.item(j);
                    if (attribute) {
                        params[attribute.name] = attribute.value;
                    }
                }
                if (params.id) {
                    switch (node.nodeName) {
                        case "circle": {
                            // tylko silosy moga byc jako circle
                            if (key !== "devices") break;
                            items.push({
                                id: params.id,
                                type: "circle",
                                cx: +params.cx,
                                cy: +params.cy,
                                r: +params.r,
                                layer: key,
                            });
                            break;
                        }
                        case "path": {
                            // supportujemy proste pathe wiec jesli jest cos innego to wywalamy
                            if (!params.d.match(/[mlHhVvCcSsQqTtAaz]/)) {
                                items.push({
                                    id: params.id,
                                    type: "path",
                                    d: params.d,
                                    orientation:
                                        params["aria-orientation"] || null,
                                    layer: key,
                                });
                            }
                            break;
                        }
                        case "rect": {
                            items.push({
                                id: params.id,
                                type: "path",
                                ...PathParser.createFromRect(
                                    params.x,
                                    params.y,
                                    params.width,
                                    params.height
                                ).getParams(),
                                layer: key,
                            });
                            break;
                        }
                        default:
                            break;
                    }
                }
            });
        }
        levels[levelKey === "bg" ? UniqueEntitiesLevel : +levelKey] = items;
    }
    return {levels, size};
};

function checkIfIsUniqueEntity(id) {
    const ueNames = Object.values(UniqueEntities);
    for (let name of ueNames) {
        if (id?.startsWith(name)) return true;
    }
    return false;
}

export const generateSVGFromItems = (items = [], height, width) => {
    const state = store.getState();
    const buildingsMap = getBuildingsMap(state);
    const svg = {
        buildings: [],
        devices: [],
        sectors: [],
        chambers: [],
        others: [],
    };
    const parseItem = (item) => {
        switch (item.type) {
            case "path":
                return ["horizontal", "horizontal-ml", "vertical-ml"].includes(
                    item.orientation
                )
                    ? `<path id="${item.id}" d="${item.d}" aria-orientation="${item.orientation}"/>`
                    : `<path id="${item.id}" d="${item.d}"/>`;
            case "circle":
                return `<circle id="${item.id}" cx="${item.cx}" cy="${item.cy}" r="${item.r}"/>`;
            default:
                return null;
        }
    };
    const generatedSectors = {};
    const generatedBuildings = {};
    const addToSector = (item, parentId) => {
        if (parentId) {
            const sector = buildingsMap.get(parentId);
            if (!sector || sector.level !== Level.SECTOR) return;
            generatedSectors[sector.id] ||= "Z";
            generatedSectors[sector.id] =
                generatedSectors[sector.id].slice(0, -1) + item.d;
            const building = buildingsMap.get(sector.parentId);
            if (building && building.level === Level.BUILDING) {
                generatedBuildings[building.id] ||= "Z";
                generatedBuildings[building.id] =
                    generatedBuildings[building.id].slice(0, -1) + item.d;
            }
        }
    };
    // zakldamy ze pathy to lokacje a circle to devices
    for (let item of items) {
        if (item.layer === "devices") {
            svg.devices.push(item);
        } else {
            if (checkIfIsUniqueEntity(item.id)) {
                svg.others.push(item);
            } else {
                const location = buildingsMap.get(item.id);
                if (!location || location.level !== Level.CHAMBER) continue;
                svg.chambers.push(item);
                addToSector(item, location.parentId);
            }
        }
    }
    for (let [id, d] of Object.entries(generatedSectors)) {
        svg.sectors.push({type: "path", id, d});
    }
    for (let [id, d] of Object.entries(generatedBuildings)) {
        svg.buildings.push({type: "path", id, d});
    }
    let svgAsText = [
        `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">`,
    ];
    svg.others = getSortedBackgroundItems(svg.others, "id");
    for (let key in svg) {
        const tmp = [`<g id="${key}">`];
        for (let item of svg[key]) {
            const parsed = parseItem(item);
            if (parsed) tmp.push(parsed);
        }
        tmp.push("</g>");
        svgAsText.push(tmp.join(""));
    }
    svgAsText.push("</svg>");
    svgAsText = svgAsText.join("\n");
    return svgAsText;
};

export const checkIfLevelsAreEmpty = (levels) => {
    for (let l of levels) {
        if (l && l.length) {
            return false;
        }
    }
    return true;
};

export const getFontSize = ({x1, x2, y1, y2}, text, defaultFont, angle = 0) => {
    let fontSize = defaultFont;
    let maxWidth = x2 - x1,
        maxHeight = y2 - y1;
    if (angle) {
        const {x, y} = rotateCartesian(maxWidth, maxHeight, angle);
        maxWidth = Math.abs(x);
        maxHeight = Math.abs(y);
    }
    fontSize = Math.min(maxHeight, fontSize * 1.2);
    const currentWidth = ((2.6 * fontSize) / 4) * text.length;
    if (currentWidth > maxWidth) {
        fontSize = fontSize / (currentWidth / maxWidth);
    }
    return Math.floor(fontSize * 5) / 5;
};

export const checkIfMapHasIDs = memoizeOne((farmMapObj, ids = []) => {
    const version = get(farmMapObj, "SetData.Version", 0);
    if (version < Backup.VERSION[Backup.TYPE.FARM_MAP]) return false;
    const levelsAsString = JSON.stringify(
        get(farmMapObj, "SetData.Levels", {})
    ).replace(/\\"/g, '"');
    const foundIdsInMap = [
        ...levelsAsString.match(/id="([^"]*?)"/g).map((o) => o.slice(4, -1)),
    ];
    for (let id of ids) {
        if (!foundIdsInMap.includes(id)) return false;
    }
    return true;
});

export const getSortedBackgroundItems = (bgItems, pathToId = "id") => {
    const itemsSorted = bgItems.slice(0); // nie chcemy mutowac tablicy
    const cache = {};
    const getSortingIndex = (id) => {
        const name = id.split("_")[0];
        if (cache[name] === undefined)
            cache[name] = Object.values(UniqueEntities).findIndex(
                (o) => o === name
            );
        return cache[name];
    };
    itemsSorted.sort((o1, o2) => {
        return (
            getSortingIndex(get(o1, pathToId)) -
            getSortingIndex(get(o2, pathToId))
        );
    });
    return itemsSorted;
};

export const hasOffscreenCanvas = () => {
    try {
        return !!OffscreenCanvas;
    } catch (err) {
        return false;
    }
};

export const FARM_MAP_SCALING = {
    AUTO: "auto",
    DISABLED: "disabled",
    ENABLED: "enabled",
};

// naprawia #11677
// composition layer na safari nie może być za duży (mimo że i tak to jest SVG) - więc skalujemy w dół, żeby był mniejszy
// wywala się na iPad-zie
export const PRE_PROCESS_SCALING = (() => {
    const scaling = localStorage?.getItem?.("_farm_map_scaling_") ?? "auto";
    console.log("_farm_map_scaling_.MECHANISM=%s", scaling);
    const MIN_SAFE_SCALLING = 0.5;
    switch (scaling) {
        case FARM_MAP_SCALING.AUTO: {
            return Browser.is.Safari() && Browser.is.Mobile()
                ? MIN_SAFE_SCALLING
                : 1;
        }
        case FARM_MAP_SCALING.ENABLED: {
            return MIN_SAFE_SCALLING;
        }
        case FARM_MAP_SCALING.DISABLED: {
            return 1;
        }
        default: {
            if (scaling && isFiniteNumber(+scaling)) return +scaling;
            return 1;
        }
    }
})();

console.log("_farm_map_scaling_.PRE_PROCESS_SCALING=%s", PRE_PROCESS_SCALING);

export const objectParser = (object, fixRotation = false) => {
    let parsedObj = parser(object.nodeName, object);
    const extraProps = {};
    if (parsedObj) {
        if (fixRotation && parsedObj.makeRotatedRectGreatAgain) {
            const {rotation, d} = parsedObj.makeRotatedRectGreatAgain(true);
            if (rotation) {
                extraProps.rotation = rotation;
                parsedObj = parser(object.nodeName, d);
            }
        }
        // trzeba skalowac svg wczesniej, ale tylko raz!
        if (PRE_PROCESS_SCALING !== 1 && !fixRotation) {
            parsedObj.scale(PRE_PROCESS_SCALING, 0, 0);
        }
        return {
            ...object,
            ...parsedObj.getParams(),
            fill: `#${Math.round(Math.random() * 255 * 255 * 255).toString(16)}`,
            _view: parsedObj.getRect(),
            ...parsedObj.getInsideRectParams(),
            ...(!!extraProps.rotation && {_angle: extraProps.rotation}),
        };
    } else {
        return null;
    }
};

export const preProcessLevels = (levels) => {
    const levelsCopy = {};
    // niestety trzeba troche przerobic dane przed wrzuceniem do workera bo worker nie ma dostepu do DOM
    for (let key in levels) {
        if (!levels.hasOwnProperty(key)) continue;
        if (!levelsCopy[key]) levelsCopy[key] = [];
        for (let item of levels[key]) {
            const result = objectParser(item);
            if (!result) continue;
            levelsCopy[key].push(result);
        }
    }
    return levelsCopy;
};

export const devicesRenderedInsideChamber = {
    [DevTypes.DISPENSER_NRF]: (device) => isRFID(device),
};

export const devicesRenderedStandalone = {
    [DevTypes.DISPENSER_NRF]: (device) =>
        isNutriPro(device) || isNutriProV2(device) || isIPSUM(device),
    [DevTypes.MODBUS_RELAY]: () => true,
    [DevTypes.SCALE]: () => true,
    [DevTypes.SILO_SENSOR]: () => true,
    [DevTypes.SILO_RADAR]: () => true,
    [DevTypes.CLIMATE_SK3]: () => true,
    [DevTypes.CLIMATE_SK4]: () => true,
    [DevTypes.CLIMATE]: () => true,
    [DevTypes.TEMP_SENSOR]: () => true,
    [DevTypes.ELECTRICITY_FLOW_METER]: () => true,
    [DevTypes.ELECTRICITY_FLOW_METER_MODBUS]: () => true,
    [DevTypes.WATER_FLOW_METER]: () => true,
    [DevTypes.WIRELESS_WATER_FLOW_METER]: () => true,
    [DevTypes.CAGE_2WAY]: () => true,
    [DevTypes.VEHICLE_WEIGHT_R320]: () => true,
};

export const getDeviceEntityType = (device) => {
    const handler = deviceToEntityType[device?.DevType];
    return handler ? handler(device) : null;
};

const deviceToEntityType = {
    [DevTypes.DISPENSER_NRF]: (device) => {
        if (isRFID(device)) return CustomEntityTypes.GROUP;
        if (isIPSUM(device)) return CustomEntityTypes.IPSUM;
        if (isNutriProV2(device)) return CustomEntityTypes.NUTRI_PRO_V2;
        if (isNutriPro(device)) return CustomEntityTypes.NUTRI_PRO;
        console.warn("deviceToEntityType is null");
        return null;
    },
    [DevTypes.CAGE]: () => CustomEntityTypes.CAGE_3WAY,
    [DevTypes.CAGE_2WAY]: () => CustomEntityTypes.CAGE_2WAY,
    [DevTypes.CLIMATE_SK3]: () => CustomEntityTypes.CLIMATE,
    [DevTypes.CLIMATE_SK4]: () => CustomEntityTypes.CLIMATE,
    [DevTypes.CLIMATE]: () => CustomEntityTypes.CLIMATE,
    [DevTypes.TEMP_SENSOR]: () => CustomEntityTypes.CLIMATE,
    [DevTypes.MODBUS_RELAY]: () => CustomEntityTypes.LIGHT,
    [DevTypes.SILO_RADAR]: () => CustomEntityTypes.SILO,
    [DevTypes.SILO_SENSOR]: () => CustomEntityTypes.SILO,
    [DevTypes.SCALE]: () => CustomEntityTypes.SILO,
    [DevTypes.ELECTRICITY_FLOW_METER]: () => CustomEntityTypes.ELECTRICITY,
    [DevTypes.ELECTRICITY_FLOW_METER_MODBUS]: () =>
        CustomEntityTypes.ELECTRICITY,
    [DevTypes.WATER_FLOW_METER]: () => CustomEntityTypes.WATER,
    [DevTypes.WIRELESS_WATER_FLOW_METER]: () => CustomEntityTypes.WATER,
    [DevTypes.VEHICLE_WEIGHT_R320]: () => CustomEntityTypes.VEHICLE_WEIGHT,
};

export function createIndexLookup(boxesCount, standsInRow, order) {
    const stands = new Array(boxesCount).fill(0).map((o, i) => i);
    const sortIndexes = (index1, index2, ascending) =>
        ascending ? index1 - index2 : index2 - index1;
    const isAllDataAscending = !((order >> 1) & 0b01);
    const isSwapOnNewRow = !!((order >> 2) & 0b01);
    const isRowAscending = !!(order & 0b01);
    stands.sort((o1, o2) => sortIndexes(o1, o2, isAllDataAscending));
    let lastIndex = 0;
    const data = [];
    while (lastIndex < stands.length) {
        const startIndex = lastIndex;
        const endIndex = Math.min(lastIndex + standsInRow, stands.length);
        lastIndex = endIndex;
        const tmp = stands.slice(startIndex, endIndex);
        tmp.sort((o1, o2) =>
            sortIndexes(
                o1,
                o2,
                isSwapOnNewRow && data.length % 2 === 1
                    ? !isRowAscending
                    : isRowAscending
            )
        );
        data.push(tmp);
    }
    return flatten(data);
}

export const StandingsDrawType = {
    ROWS_DEFAULT: 0,
    ROWS_SPACING: 1,
    ROWS_SPACING_AROUND: 2,
};

const getEntityAlignmentByType = (type) => {
    switch (type) {
        case "group":
            return StandingsDrawType.ROWS_SPACING;
        case SectorType.RENOVATION_SOWS:
        case SectorType.SOWS:
            return StandingsDrawType.ROWS_SPACING;
        case SectorType.MATING:
            return StandingsDrawType.ROWS_SPACING_AROUND;
        default:
            if (customEntityTypes.includes(type))
                return StandingsDrawType.ROWS_SPACING;
            return StandingsDrawType.ROWS_DEFAULT;
    }
};

export const createAnimalGrid = ({
    width = 100,
    height = 100,
    x = 0,
    y = 0,
    collisionGrid = [],
    animalCount = 0,
    scaling = 1,
    angle = 0,
    maxAnimalArea = 256,
    meetPoint = null,
}) => {
    const collisionArea = collisionGrid.reduce(
        (a, b) => a + b.rect.width * b.rect.height,
        0
    );
    // width = scaling * width;
    // height = scaling * height;
    maxAnimalArea = maxAnimalArea * scaling;
    const chamberArea = width * height - collisionArea;
    const animalArea = Math.min(chamberArea / animalCount, maxAnimalArea);
    let animalSize = Math.sqrt(animalArea);
    const decreaseBy = 0.1 * scaling;
    const maxPadding = 12 * scaling;
    const padding =
        animalSize > maxPadding ? Math.min(animalSize - maxPadding, 0) : 0;
    const getCollXY = (colItem) => colItem.rect;
    const maxX = x + width;
    const maxY = y + height;
    const canInsertAt = (_x, _y, _animalSize) => {
        for (let col of collisionGrid) {
            const el = getCollXY(col);
            const c0 = _x < el.x2;
            const c1 = _x + _animalSize > el.x1;
            const c2 = _y < el.y2;
            const c3 = _y + _animalSize > el.y1;
            const intersects = c0 && c1 && c2 && c3;
            if (intersects) {
                if (el.x2 + _animalSize > maxX) {
                    return canInsertAt(_x, el.y2, _animalSize);
                } else {
                    return canInsertAt(el.x2, _y, _animalSize);
                }
            }
        }
        const fitsX = _x + _animalSize <= maxX;
        const fitsY = _y + _animalSize <= maxY;
        return {x: _x, y: _y, status: fitsX && fitsY};
    };
    const makeGrid = (_size, _padding) => {
        if (_size < 0) return [];
        if (_padding < 0) _padding = 0;
        const _grid = [];
        let _startX = x;
        let _startY = y;
        let _outOfBounds = false;
        let _counter = 0;
        while (_counter < animalCount && _outOfBounds === false) {
            const r = canInsertAt(_startX, _startY, _size);
            if (r.status) {
                _startX = r.x;
                _startY = r.y;
                _grid.push({
                    x: _startX,
                    y: _startY,
                    width: _size - _padding,
                    height: _size - _padding,
                });
                _counter++;
                _startX += _size;
                if (_startX + _size > maxX) {
                    _startX = x;
                    _startY += _size;
                }
            } else {
                _outOfBounds = true;
            }
        }
        return _outOfBounds
            ? makeGrid(_size - decreaseBy, _padding - decreaseBy)
            : _grid;
    };
    return makeGrid(animalSize, padding).map((raw) => {
        const o = meetPoint
            ? moveToMeetPoint(raw.x, raw.y, raw.width, raw.height, meetPoint)
            : raw;
        return getRectProps(
            o.x,
            o.y,
            o.width,
            o.height,
            x + width / 2,
            y + height / 2,
            angle,
            {
                type: "animals",
            }
        );
    });
};

const moveToMeetPoint = (x, y, w, h, meetPoint) => {
    const middle = {x: x + w / 2, y: y + h / 2};
    const len =
        getDistanceBetweenTwoPoints(
            middle.x,
            middle.y,
            meetPoint.x,
            meetPoint.y
        ) / w;
    const force = (len * w) / 2 - meetPoint.force;
    const nVec = getNormalizedVectorFromTwoPoints(middle, meetPoint);
    return {x: x - nVec.x * force, y: y - nVec.y * force, width: w, height: h};
};

export const getRectProps = (
    x,
    y,
    w,
    h,
    rotateX,
    rotateY,
    angle = 0,
    ownProps = {}
) => {
    const path = PathParser.createFromRect(x, y, w, h);
    const rect = path.getRect();
    let d = path.toString();
    let view = rect;
    if (angle) {
        path.rotate(angle, rotateX, rotateY);
        view = path.getRect();
    }
    return {
        nodeName: "path",
        d,
        ...(!!angle && {_d: path.toString()}),
        _view: view,
        rect: {
            x1: rect.x,
            x2: rect.maxX,
            y1: rect.y,
            y2: rect.maxY,
            width: rect.width,
            height: rect.height,
        },
        ...(!!angle && {_angle: {angle, x: rotateX, y: rotateY}}),
        ...ownProps,
    };
};

export const createGrid = ({
    width = 100,
    height = 100,
    angle = 0,
    position = "vertical",
    standsInRow = 0,
    rows = 0,
    order = 0,
    x = 0,
    y = 0,
    scaling = 1,
    standingWidth = 20,
    standingHeight = 24,
    standingsDrawType = StandingsDrawType.ROWS_DEFAULT,
    customStandingSize,
} = {}) => {
    let fn;
    const drawFunctionType =
        +position.startsWith("horizontal") * 10 + standingsDrawType;
    const params = {
        width,
        height,
        angle,
        position,
        standsInRow,
        rows,
        order,
        x,
        y,
        scaling,
        altPosition: position.endsWith("-ml"),
        standingHeight,
        standingWidth,
        customStandingSize,
    };
    switch (drawFunctionType) {
        case 1:
            fn = createAltGridRowsVertical;
            break;
        case 11:
            fn = createAltGridRowsHorizontal;
            break;
        case 0:
            fn = createGridRowsVertical;
            break;
        case 12:
            fn = createGridRowsHorizontal;
            params.altPadding = true;
            break;
        case 2:
            fn = createGridRowsVertical;
            params.altPadding = true;
            break;
        default:
            fn = createGridRowsHorizontal;
            break;
    }
    return fn(params);
};

const createAltGridRowsHorizontal = ({
    width = 100,
    height = 100,
    standsInRow = 0,
    rows = 0,
    x = 0,
    y = 0,
    angle = 0,
    scaling,
    altPosition = false,
    standingHeight,
    standingWidth,
    customStandingSize,
}) => {
    standingHeight = scaling * standingHeight;
    standingWidth = scaling * standingWidth;
    let offsetX = Math.min(width / standsInRow, standingWidth);
    let offsetY = Math.min(height / rows, standingHeight);
    if (offsetY !== standingHeight || offsetX !== standingWidth) {
        const scale = Math.min(
            offsetX / standingWidth,
            offsetY / standingHeight
        );
        offsetX = scale * standingWidth;
        offsetY = scale * standingHeight;
    }
    let startingX = x;
    if (altPosition) {
        // przesuwamy na srodek srodek - tj il. w rz. / 2 * szerokosc
        startingX += width / 2 - (standsInRow * offsetX) / 2;
    }
    const paddingY = (height - offsetY * rows) / rows;
    const grid = [];
    let idx = 0;
    const {getWidth, getHeight} = makeGetSize(
        offsetX,
        offsetY,
        customStandingSize
    );
    for (let row = 0; row < rows; row++) {
        for (let standing = 0; standing < standsInRow; standing++) {
            const facing = row % 2 ? "down" : "up";
            idx =
                grid.push(
                    getRectProps(
                        startingX + standing * offsetX,
                        y +
                            row * (offsetY + paddingY) +
                            +(facing === "down" ? paddingY : 0),
                        getWidth(idx),
                        getHeight(idx),
                        x + width / 2,
                        y + height / 2,
                        angle,
                        {
                            type: "standings",
                            facing,
                        }
                    )
                ) - 1;
        }
    }
    return grid;
};

const createGridRowsHorizontal = ({
    width = 100,
    height = 100,
    standsInRow = 0,
    rows = 0,
    x = 0,
    y = 0,
    angle = 0,
    scaling,
    altPosition = false,
    altPadding = false,
    standingHeight,
    standingWidth,
    customStandingSize,
}) => {
    standingHeight = scaling * standingHeight;
    standingWidth = scaling * standingWidth;
    const maxPadding = 15 * scaling;
    let offsetX = Math.min(width / standsInRow, standingWidth);
    let offsetY = Math.min(height / rows, standingHeight);
    if (offsetY !== standingHeight || offsetX !== standingWidth) {
        const scale = Math.min(
            offsetX / standingWidth,
            offsetY / standingHeight
        );
        offsetX = scale * standingWidth;
        offsetY = scale * standingHeight;
    }
    const paddingY = Math.min((height - offsetY * rows) / rows, maxPadding);
    const grid = [];
    let idx = 0;
    const {getWidth, getHeight} = makeGetSize(
        offsetX,
        offsetY,
        customStandingSize
    );
    for (let row = 0; row < rows; row++) {
        for (let standing = 0; standing < standsInRow; standing++) {
            const facing = (altPosition ? !(row % 2) : row % 2) ? "down" : "up";
            const padOptions = {
                down: paddingY,
                up: 0,
            };
            const isFirstRow = row === 0,
                isLastRow = row === rows - 1;
            if (altPadding) {
                padOptions.down = paddingY * 0.75;
                padOptions.up = paddingY * 0.25;
                if (isFirstRow && facing === "up") {
                    padOptions.up = paddingY * 0.5;
                }
                if (isLastRow && facing === "down") {
                    padOptions.down = paddingY * 0.5;
                }
            }
            const padding = padOptions[facing];
            idx =
                grid.push(
                    getRectProps(
                        x + standing * offsetX,
                        y + row * (offsetY + paddingY) + padding,
                        getWidth(idx),
                        getHeight(idx),
                        x + width / 2,
                        y + height / 2,
                        angle,
                        {
                            type: "standings",
                            facing,
                        }
                    )
                ) - 1;
        }
    }
    return grid;
};

const makeGetSize = (defaultWidth, defaultHeight, customStandingSize) => {
    const makeGetter = (key, defaultValue) => {
        return (i) => {
            if (!isFunction(customStandingSize)) return defaultValue;
            return (
                customStandingSize(i, {defaultWidth, defaultHeight})?.[key] ??
                defaultValue
            );
        };
    };
    return {
        getWidth: makeGetter("width", defaultWidth),
        getHeight: makeGetter("height", defaultHeight),
    };
};

const createAltGridRowsVertical = ({
    width = 100,
    height = 100,
    standsInRow = 0,
    rows = 0,
    x = 0,
    y = 0,
    angle = 0,
    scaling,
    altPosition = false,
    standingHeight,
    standingWidth,
    customStandingSize,
}) => {
    standingHeight = scaling * standingHeight;
    standingWidth = scaling * standingWidth;
    let offsetX = Math.min(width / rows, standingHeight);
    let offsetY = Math.min(height / standsInRow, standingWidth);
    if (offsetY !== standingWidth || offsetX !== standingHeight) {
        const scale = Math.min(
            offsetX / standingHeight,
            offsetY / standingWidth
        );
        offsetX = scale * standingHeight;
        offsetY = scale * standingWidth;
    }

    const paddingX = (width - offsetX * rows) / rows;
    let startingY = y;
    if (altPosition) {
        // przesuwamy na srodek srodek - tj il. w rz. / 2 * szerokosc
        startingY += height / 2 - (standsInRow * offsetY) / 2;
    }

    const grid = [];
    let idx = 0;
    const {getWidth, getHeight} = makeGetSize(
        offsetX,
        offsetY,
        customStandingSize
    );
    for (let row = 0; row < rows; row++) {
        const facing = row % 2 ? "right" : "left";
        for (let standing = 0; standing < standsInRow; standing++) {
            idx =
                grid.push(
                    getRectProps(
                        x +
                            row * (offsetX + paddingX) +
                            (facing === "right" ? paddingX : 0),
                        startingY + standing * offsetY,
                        getWidth(idx),
                        getHeight(idx),
                        x + width / 2,
                        y + height / 2,
                        angle,
                        {
                            type: "standings",
                            facing,
                        }
                    )
                ) - 1;
        }
    }
    return grid;
};

const createGridRowsVertical = ({
    width = 100,
    height = 100,
    standsInRow = 0,
    rows = 0,
    x = 0,
    y = 0,
    angle = 0,
    scaling,
    altPosition = false,
    altPadding = false,
    standingHeight,
    standingWidth,
    customStandingSize,
}) => {
    standingHeight = scaling * standingHeight;
    standingWidth = scaling * standingWidth;
    const maxPadding = 15 * scaling;
    let offsetX = Math.min(width / rows, standingHeight);
    let offsetY = Math.min(height / standsInRow, standingWidth);
    if (offsetY !== standingWidth || offsetX !== standingHeight) {
        const scale = Math.min(
            offsetX / standingHeight,
            offsetY / standingWidth
        );
        offsetX = scale * standingHeight;
        offsetY = scale * standingWidth;
    }
    const paddingX = Math.min((width - offsetX * rows) / rows, maxPadding);
    const grid = [];
    let idx = 0;
    const {getWidth, getHeight} = makeGetSize(
        offsetX,
        offsetY,
        customStandingSize
    );
    for (let row = 0; row < rows; row++) {
        const facing = (altPosition ? !(row % 2) : row % 2) ? "right" : "left";

        for (let standing = 0; standing < standsInRow; standing++) {
            const padOptions = {
                right: paddingX,
                left: 0,
            };
            const isFirstRow = row === 0,
                isLastRow = row === rows - 1;
            if (altPadding) {
                padOptions.right = paddingX * 0.75;
                padOptions.left = paddingX * 0.25;
                if (isFirstRow && facing === "left") {
                    padOptions.left = paddingX * 0.5;
                }
                if (isLastRow && facing === "right") {
                    padOptions.right = paddingX * 0.5;
                }
            }
            const padding = padOptions[facing];
            idx =
                grid.push(
                    getRectProps(
                        x + row * (offsetX + paddingX) + padding,
                        y + standing * offsetY,
                        getWidth(idx),
                        getHeight(idx),
                        x + width / 2,
                        y + height / 2,
                        angle,
                        {
                            type: "standings",
                            facing,
                        }
                    )
                ) - 1;
        }
    }
    return grid;
};

const getEntitySizeByType = (type) => {
    return getEntitySizeByFillName(getEntityFillByType(type));
};

const getEntitySizeByFillName = (fillName) => {
    switch (fillName) {
        case "standing-small":
            return {width: 7, height: 24};
        case "standing-group":
            return {width: 7, height: 24};
        case CustomEntityTypes.CAGE_2WAY:
        case CustomEntityTypes.CAGE_3WAY:
            return {width: 20, height: 20};
        case CustomEntityTypes.IPSUM:
            return {width: 7, height: 20};

        default:
            return {width: 20, height: 24};
    }
};

export const CustomEntityTypes = {
    GROUP: "group",
    NUTRI_PRO: "nutripro",
    NUTRI_PRO_V2: "nutriprov2",
    IPSUM: "ipsum",
    CAGE_2WAY: "cage2way",
    CAGE_3WAY: "cage3way",
    CLIMATE: "climate",
    WATER: "water",
    ELECTRICITY: "electricity",
    LIGHT: "lights",
    SILO: "siloses",
    VEHICLE_WEIGHT: "vehicleWeight",
};

const customEntityTypes = Object.values(CustomEntityTypes);

export const isStandaloneEntity = memoize((entityType) => {
    const standaloneCustomEntities = [
        CustomEntityTypes.VEHICLE_WEIGHT,
        CustomEntityTypes.CAGE_2WAY,
        CustomEntityTypes.NUTRI_PRO,
        CustomEntityTypes.NUTRI_PRO_V2,
        CustomEntityTypes.IPSUM,
        CustomEntityTypes.SILO,
    ];
    return standaloneCustomEntities.includes(entityType);
});

export const mustBeDrawnInsideChamberIfPossible = (entityType) => {
    if (CustomEntityTypes.VEHICLE_WEIGHT === entityType) return false;
    return CustomEntityTypes.SILO !== entityType;
};

export const shouldCollide = memoize(isStandaloneEntity);

export const getEntityFillByType = (type) => {
    switch (type) {
        case SectorType.RENOVATION_SOWS:
        case SectorType.MATING:
        case SectorType.SOWS:
            return "standing-small";
        case CustomEntityTypes.GROUP:
            return "standing-group";
        default:
            if (customEntityTypes.includes(type)) return type;
            return "standing";
    }
};

export const getEntityParamsByType = (entityType) => {
    return {
        size: getEntitySizeByType(entityType),
        type: getEntityFillByType(entityType),
        align: getEntityAlignmentByType(entityType),
    };
};

export const getDeviceFixedParams = (device) => {
    const empty = {};
    switch (device?.DevType) {
        case DevTypes.SILO_RADAR:
            return {r: getDiameter(device) / 10 / 2 || 35, reinitialize: true};
        case DevTypes.CAGE_2WAY:
            return {
                width: 20,
                height: 20,
                fill: `url(#ue-${getEntityFillByType(CustomEntityTypes.CAGE_2WAY)})`,
                fixRotation: true,
                showCollision: false,
            };
        case DevTypes.DISPENSER_NRF: {
            if (isNutriPro(device))
                return {
                    width: 6,
                    height: 12,
                    fill: `url(#ue-${getEntityFillByType(CustomEntityTypes.NUTRI_PRO)})`,
                    fixRotation: true,
                    showCollision: false,
                };
            if (isNutriProV2(device))
                return {
                    width: 6,
                    height: 12,
                    fill: `url(#ue-${getEntityFillByType(CustomEntityTypes.NUTRI_PRO_V2)})`,
                    fixRotation: true,
                    showCollision: false,
                };
            if (isIPSUM(device))
                return {
                    width: 7,
                    height: 20,
                    fill: `url(#ue-${getEntityFillByType(CustomEntityTypes.IPSUM)})`,
                    fixRotation: true,
                    showCollision: false,
                };
            return empty;
        }
        default:
            return empty;
    }
};

export const textIdToObject = (id) => {
    if (/_[0-9]{1,2}$/.test(id)) {
        const possiblyAnIndex = id.split("_").pop();
        if (possiblyAnIndex && isFiniteNumber(+possiblyAnIndex)) {
            return {
                index: +possiblyAnIndex,
                id: `${id}`.replace(new RegExp(`_${possiblyAnIndex}$`), ""),
            };
        }
    }
    return {id};
};

export const getAnimalSizeNameBySize = (cnt) => {
    if (cnt <= 10) return Math.max(cnt, 1);
    if (cnt >= 500) return 500;
    return 100;
};

export const getAnimalSizeClassName = (animal) => {
    if (
        [AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(animal?.AnimalKind) &&
        animal?.DtaBrthTime
    ) {
        const weeks = moment()
            .startOf("day")
            .diff(moment(animal.DtaBrthTime).startOf("day"), "weeks");
        if (weeks > 0) {
            let weightTable = getAnimalWeightsTable(animal);
            let shouldWeight =
                weightTable[weeks] || weightTable[weightTable.length - 1];
            if (shouldWeight >= 100 * 1000) return "md";
            if (shouldWeight >= 50 * 1000) return "sm";
            return "xs";
        }
    }
    if (animal?.AnimalKind === AnimalTypes.PIGLET) return "xs";
    if (animal?.AnimalKind === AnimalTypes.PORKER) return "sm";
    return "md";
};

export const groupAnimalsBySize = (animals) => {
    const getSize = ({AnmCnt}) => {
        const cnt = AnmCnt || 1;
        if (cnt >= 500) return 40 * 40;
        if (cnt > 10) return 36 * 36;
        return Math.pow(14 + cnt * 2, 2);
    };
    return groupBy(animals, getSize);
};

export const getDeviceSubtype = (device) => {
    return getDeviceEntityType(device) ?? "";
};

export const makeExtractKeys = () => {
    return memoizeOne((devices) => {
        const keys = [];
        for (const {index, device} of devices) {
            if (isFiniteNumber(index)) {
                keys.push(`${device.DevID}_${index}`);
            } else {
                keys.push(device.DevID);
            }
        }
        return keys;
    });
};

const ORIENTATION = {
    COLLINEAR: 0,
    CLOCKWISE: 1,
    COUNTERCLOCKWISE: 0,
};

// based on https://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
export const makeDoIntersect = (epsilon = 0) => {
    const getOrientation = (p, q, r) => {
        const value = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
        return isBetween(value, -epsilon, epsilon)
            ? ORIENTATION.COLLINEAR
            : value > 0
              ? ORIENTATION.CLOCKWISE
              : ORIENTATION.COUNTERCLOCKWISE;
    };
    return (p1, q1, p2, q2) => {
        const [o1, o2, o3, o4] = [
            [p1, q1, p2],
            [p1, q1, q2],
            [p2, q2, p1],
            [p2, q2, q1],
        ].map((args) => getOrientation(...args));
        return o1 !== o2 && o3 !== o4;
    };
};

export const doIntersect = makeDoIntersect(Number.EPSILON);

export const limitCanvasSize = (
    size,
    maximumPixels = MAX_CANVAS_SIZE_SAFARI
) => {
    const w = Math.floor(size.width);
    const h = Math.floor(size.height);
    const requiredPixels = w * h;

    if (requiredPixels <= maximumPixels) return {width: w, height: h, scale: 1};

    const scale = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);

    return {
        width: Math.floor(w * scale),
        height: Math.floor(h * scale),
        scale,
    };
};

export const getLargestRectInsidePoly = (canvasId, d, options = {}) => {
    options.rasterizationMaxResolution ||= 256 * 256; // the less the faster its calculated
    options.rasterizationMaxResolution = Math.min(
        MAX_CANVAS_SIZE_SAFARI,
        options.rasterizationMaxResolution
    );
    const path = new PathParser(d);
    const rect = path.getRect();
    let result = {
        x1: rect.minX,
        x2: rect.maxX,
        y1: rect.minY,
        y2: rect.maxY,
        width: 0,
        height: 0,
    };
    try {
        // get starting point of object
        const starting = {
            X: Math.ceil(rect.x),
            Y: Math.ceil(rect.y),
            w: Math.floor(rect.width),
            h: Math.floor(rect.height),
        };
        // resize canvas if its too big (safari has an issue with large canvases)
        const {scale, width, height} = limitCanvasSize(
            rect,
            options.rasterizationMaxResolution
        );
        const canvas = hasOffscreenCanvas()
            ? new OffscreenCanvas(width, height)
            : document.getElementById(canvasId);
        path.translate(-starting.X, -starting.Y);
        if (scale !== 1) {
            path.scaleXY(scale, scale, 0, 0);
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d", {willReadFrequently: true});
        ctx.fillStyle = `rgba(0, 0, 0, 1)`;
        ctx.fill(path.getPath2D());
        const w = width + 2,
            h = height + 2;
        const data = ctx.getImageData(0, 0, w, h).data;
        const pixelAt = (x, y) => {
            return +!!data[3 + x * 4 + y * w * 4];
        };
        const biggestRect = getBiggestRectInMatrix(pixelAt, w, h);
        // get width and height by subtracting min and max
        biggestRect.width = biggestRect.x2 - biggestRect.x1;
        biggestRect.height = biggestRect.y2 - biggestRect.y1;
        // apply scaling and relative pos to absolute cords
        result.x1 += Math.round(biggestRect.x1 / scale);
        result.x2 = Math.floor(biggestRect.width / scale + result.x1);
        result.y1 += Math.round(biggestRect.y1 / scale);
        result.y2 = Math.floor(biggestRect.height / scale + result.y1);
        result.width = Math.abs(result.x2 - result.x1);
        result.height = Math.abs(result.y2 - result.y1);
        clearCanvas(canvas);
    } catch (e) {
        console.error(e);
    }
    return result;
};

export function isSupportedVersion(farmMap) {
    return (
        get(farmMap, "SetData.Version", 0) >=
        Backup.VERSION[Backup.TYPE.FARM_MAP]
    );
}

export function sortDevicesByTypeAndAddr(devices, mutateOrgArray = false) {
    const typeSort = makeEnhancedComparer("DevType");
    const addrSort = makeEnhancedComparer("Address", {numeric: true});
    const sortFn = (o1, o2) => typeSort(o1, o2) || addrSort(o1, o2);
    if (!mutateOrgArray) return devices.slice().sort(sortFn);
    devices.sort(sortFn);
    return devices;
}
