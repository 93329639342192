import {isNil} from "lodash";
import {
    minutesFormatter,
    temperatureFormatter,
} from "../views/new-settings-view/climate/manage-sk3/settings/utils";
import {weightValueFormatterWithUnit} from "../components/devices-rows/cage/utils";
import {formatLocationName} from "./global-formatters/formatLocationName";
import {localDateTimeFormatter, localTimeFormatter} from "./DateTimeUtils";

export const getAlarmParams = (code, info) => {
    let key = `notificationsCenter.errorCodes.${code}`;
    let params = {};
    switch (code) {
        case 3010:
        case 3011:
        case 3012:
        case 3050:
            params.temperature = isNil(info.temperature)
                ? "-"
                : temperatureFormatter(info.temperature);
            break;
        case 4101:
            if (isNil(info.weight)) {
                key = `notificationsCenter.errorCodes.S${code}`;
            } else {
                params.weight = weightValueFormatterWithUnit(info.weight);
            }
            break;
        case 5108:
        case 5001:
            params.number = info.RFID || "-";
            params.location = formatLocationName(info.Plcmnt);
            params.time = localDateTimeFormatter(info.LastSeenTime);
            break;
        case 5002:
            params.number = info.RFID || "-";
            params.expectedLoc = formatLocationName(info.expectedLocation);
            params.currentLoc = formatLocationName(info.currentLocation);
            break;
        case 5003:
            params.start = localTimeFormatter(info.start);
            params.stop = localTimeFormatter(info.stop);
            params.percent = isNil(info.percent) ? "-" : `${info.percent}%`;
            break;
        case 5004:
        case 5005:
        case 5006:
            params.days = info.days;
            break;
        case 5101:
        case 5102:
        case 5103:
        case 5202:
        case 5204:
            params.number = info.number || "-";
            break;
        case 5104:
            // tutaj powinien byc numer rfid ale najwidoczniej nie zawsze jest wysyłany
            if (!info.number) {
                key = `notificationsCenter.errorCodes.S${code}`;
            } else {
                params.number = info.number || "-";
            }
            break;
        case 5107:
        case 5308:
            params.animal = info.RFID || "-";
            params.loc = formatLocationName(info.PlcmntID);
            params.time = info.time || 36;
            break;
        case 1430:
            params.time = localTimeFormatter(info.Time);
            break;
        case 5109:
            params.location = formatLocationName(info.PlcmntID);
            break;
        case 5606:
            if (info.time > 0) {
                // literally no one:
                // me:
                // https://i.imgur.com/cL79Qbj.jpg
                params.time = minutesFormatter(info.time || 0);
                key = `${key} ($t(settings.from) {{time}})`;
            }
            break;
        default:
            break;
    }
    return {key, params};
};
