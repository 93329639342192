import {getIOTAttributes} from "../../utils/IOTUtils";
import NewIOT from "../NewIOT";
import i18next from "../../i18n";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import {isFunction, pick, omit, isEmpty} from "lodash";

/**
 * @param commandName
 * @param translationKey
 * @param keys
 * @param defaultParams
 * @return {(function(string, {}=, {onFailure: function, onSuccess: function, showNotification?: boolean}=): (string|undefined))|*}
 */
export const makeFunction = (
    commandName,
    translationKey,
    keys = [],
    defaultParams
) => {
    return (
        _device,
        _params = {},
        {
            onFailure,
            onSuccess,
            showNotification = defaultParams?.showNotification ?? true,
        } = {}
    ) => {
        const params = pick(_params, keys);
        const omittedParams = omit(_params, keys);
        if (!isEmpty(omittedParams)) {
            console.warn(
                "[command] %s: found unsupported parameters %s",
                commandName,
                omittedParams
            );
        }
        if (defaultParams?.overrideParams) {
            Object.assign(params, defaultParams.overrideParams);
        }
        const {ClientID, LocalUserID, GatewayID, DevID, isValid, Name} =
            getIOTAttributes(_device);
        if (isValid) {
            return NewIOT.createAndSendMessageObject(
                ClientID,
                GatewayID,
                LocalUserID,
                DevID,
                commandName,
                params,
                showNotification
                    ? {
                          loading: {
                              title: i18next.t(`IOT.${translationKey}`),
                              message: i18next.t(`IOT.${translationKey}Text`, {
                                  name: Name,
                              }),
                              status: "loading",
                              dismissible: false,
                              dismissAfter: 0,
                          },
                          success: {
                              message: i18next.t(
                                  `IOT.${translationKey}Success`,
                                  {name: Name}
                              ),
                              dismissible: true,
                              dismissAfter: 3000,
                              status: "success",
                          },
                          error: {
                              message: i18next.t(
                                  `IOT.${translationKey}Failure`,
                                  {name: Name}
                              ),
                              dismissible: true,
                              dismissAfter: 3000,
                              status: "error",
                          },
                          DevID: DevID,
                      }
                    : null,
                onSuccess,
                onFailure
            );
        } else if (isFunction(onFailure)) {
            onFailure(new Error("getIOTAttributes failed!"));
        }
    };
};

export const makePromise = (...args) => {
    const fun = makeFunction(...args);
    return (device, params) => {
        return new Promise((resolve, reject) => {
            fun(device, params, {onFailure: reject, onSuccess: resolve});
        });
    };
};

export const createNotification = (translationKey, name = "") => {
    const id = myID();
    const showNotification = (data) => {
        store.dispatch(
            notify({
                id: id,
                title: notifications.loading.title,
                ...data,
            })
        );
    };
    const notifications = {
        loading: {
            title: i18next.t(`IOT.${translationKey}`),
            message: i18next.t(`IOT.${translationKey}Text`, {name: name}),
            status: "loading",
            dismissible: false,
            dismissAfter: 0,
        },
        success: {
            message: i18next.t(`IOT.${translationKey}Success`, {name: name}),
            dismissible: true,
            dismissAfter: 3000,
            status: "success",
        },
        error: {
            message: i18next.t(`IOT.${translationKey}Failure`, {name: name}),
            dismissible: true,
            dismissAfter: 3000,
            status: "error",
        },
    };
    return {
        loading: () => showNotification(notifications.loading),
        success: () => showNotification(notifications.success),
        error: () => showNotification(notifications.error),
    };
};
