import {get, isEmpty} from "lodash";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import {LicPackageLevel} from "../constans/roles";

const DEBUG = false;
const debug = (...args) =>
    DEBUG ? console.log("[WithRolesUtils.displayComponent]", ...args) : {};

/**
 * szybkie sprawdzenie czy id placementu znajduje sie w obębie innego id
 * @param _plcmntId
 * @param _edgePlcmntId
 * @param buildingsList
 * @return {boolean}
 */
const isPlacementIdAscendantOfAnotherId = (
    _plcmntId,
    _edgePlcmntId,
    buildingsList
) => {
    const edge = buildingsList.find(({id}) => _edgePlcmntId === id);
    // nie znalizlismy edga wiec mozna od razu wyjsc
    debug("edge", edge);
    if (!edge) return false;
    let evaluating = buildingsList.find(({id}) => id === _plcmntId);
    while (evaluating) {
        debug("evaluatingId", evaluating.id);
        if (evaluating.id === edge.id) return true;
        evaluating = buildingsList.find(({id}) => id === evaluating.parentId);
    }
    return false;
};

/**
 * specjalne role, które odbiegają od zwykłych tym, że nie potrzeba mieć do nich przypisanej lokalizacji
 * @type {{}}
 * @private
 */
const _deviceRolesWithoutLocation = {
    [RoleTypes.DEVICE_FARM_WATCH_PRO_READ]: true,
    [RoleTypes.DEVICE_FARM_WATCH_PRO_WRITE]: true,
};

export const displayComponent = (
    roles = [],
    clientPackage,
    devPlcmnts,
    farm,
    user,
    buildingsMap,
    isService,
    showServiceWarning,
    hideFor = []
) => {
    debug(
        "args",
        roles,
        clientPackage,
        devPlcmnts,
        farm,
        user,
        buildingsMap,
        isService
    );
    // sprawdzanie pakietów licencyjnych
    let hasClientPackage = false;
    if (hideFor.some((type) => type === user.UserType)) return false;
    if (clientPackage) {
        const userPackage = get(user, `Packages[${farm}]`, {});
        // jesli nie ma pakietow licencyjnych na obiekcie usera to wychodzimy
        if (isEmpty(userPackage)) return false;
        const validate = (clientKey, userKey) => {
            const requestedLevel = clientPackage[clientKey];
            if (!requestedLevel) return false;
            const hasBasicLevel = userPackage[`${userKey}Basic`];
            const hasExtendedLevel = userPackage[`${userKey}Ext`];
            let isValid = false;
            if (requestedLevel === LicPackageLevel.BASIC && hasBasicLevel)
                isValid = true;
            if (
                requestedLevel === LicPackageLevel.EXTENDED &&
                hasBasicLevel &&
                hasExtendedLevel
            )
                isValid = true;
            debug("validate package", userKey, isValid);
            return !!isValid;
        };
        // musi mieć przynajmniej jeden z pakietów aby komponent się wyświetlił
        if (
            validate("administration", "adm") ||
            validate("alarms", "al") ||
            validate("cages", "cg") ||
            validate("chains", "ch") ||
            validate("climates", "cl") ||
            validate("counters", "cnt") ||
            validate("dispensers5G", "di5G") ||
            validate("dispensers", "di") ||
            validate("porkers", "pork") ||
            validate("smallCages", "sc") ||
            validate("sows", "sow") ||
            validate("files", "files") ||
            validate("siloses", "sl") ||
            validate("farmMap", "farmMap") ||
            validate("ocr", "ocr") ||
            validate("ipsum", "ipsum") ||
            validate("nutriPRO", "nutriPRO")
        ) {
            hasClientPackage = true;
        }
    }
    // sprawdzanie roli tylko dla nie-serwisu
    debug("isService", isService);
    if (isService && showServiceWarning && !hasClientPackage && clientPackage)
        return false;
    if (!isService) {
        if (Object.keys(clientPackage || {}).length && !hasClientPackage)
            return false;
        for (let role of roles) {
            let hasRole = get(user, "Roles", []).find((r) => {
                if (Array.isArray(role)) {
                    const foundedRole = role.find(
                        (optionalRole) => optionalRole === r.Role
                    );
                    if (foundedRole) role = foundedRole;
                }
                return r.Role === role;
            });
            debug("hasRole", hasRole);
            if (!hasRole) return false;
            // sprawdzenie czy użytkownik ma prawa do aktualnej farmy, sprawdza czy jest farma, aby móc pokazać farmChoosera
            debug("hasFarm", farm);
            if (farm) {
                let hasRightsToFarm = hasRole.LocalRights.find(
                    (item) => item.FarmID === farm
                );
                debug("hasRightsToFarm", hasRightsToFarm);
                if (!hasRightsToFarm) return false;
                debug("devPlcmnts", devPlcmnts);
                // jesli przekazano devPlcmnts jako arraya walidujemy pod katem posiadanych rol
                if (Array.isArray(devPlcmnts)) {
                    // jeśli jest ono puste to tylko serwis, chyba z mamy do czynienia ze specjalna rola
                    if (isEmpty(devPlcmnts)) {
                        return _deviceRolesWithoutLocation[role] ?? false;
                    } else {
                        // device config nie ma plcmts wiec sprawdzamy czy zostala przekazana jeszcze inna role z devicami
                        if (
                            role !== RoleTypes.DEVICE_CONFIG &&
                            role.startsWith("_DEVICE")
                        ) {
                            // role do oczytow nie moga byc do miejsc przypisane
                            const rolePlmcnts = get(
                                hasRightsToFarm,
                                "Plcmnts",
                                []
                            );
                            if (
                                role.endsWith("_READ") &&
                                rolePlmcnts.length === 0
                            )
                                return true;
                            // jesli user ma gwiazdke to przepuszczamy
                            if (rolePlmcnts[0] === "*") {
                                return true;
                            } else {
                                debug("rolePlmcnts", rolePlmcnts);
                                // jesli ma jakies wspolne plcmnty to przepuszczamy, w innym wypadku trzeba sprawdzić czy przypadkiem plcmtn device'a
                                // nie zawiera sie w tym co ma user
                                return rolePlmcnts.some((rolePlacementId) => {
                                    return devPlcmnts.some(
                                        (devicePlacementId) => {
                                            return isPlacementIdAscendantOfAnotherId(
                                                devicePlacementId,
                                                rolePlacementId,
                                                buildingsMap
                                            );
                                        }
                                    );
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    return true;
};
