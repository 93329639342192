import {get, isEqual, isNil} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";
import {AnimalTypes} from "../../../constans/animalTypes";
import animalsDB from "../../../database/animalsDB";
import groupsDB from "../../../database/groupsDB";
import {getFarmID} from "../../../selectors/farmSelector";
import {isMobile} from "../../../utils/MobileUtils";
import {getManageSubgroups} from "../../../utils/SettingsUtils";
import {handleAnimalInventoryRowClick} from "../../../utils/URLUtils";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";
import ALink from "../../basics/alink/ALink";

function mapStateToProps(state) {
    return {
        farm: getFarmID(state),
    };
}

class AnimalReferenceDocumentModalGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDocumentItem: this.getSelectedItem(),
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {object, GrNo1, AnmGrp} = this.props;
        if (
            !isEqual(prevProps.object, object) ||
            !isEqual(prevProps.GrNo1, GrNo1) ||
            !isEqual(prevProps.AnmGrp, AnmGrp)
        ) {
            this.setState({
                selectedDocumentItem: this.getSelectedItem(),
            });
        }
    }

    getSelectedItem() {
        const {object, GrNo1, AnmGrp, farm} = this.props;
        if (object?.AnmGrp) return object;
        if (!!GrNo1) {
            // możliwość szukania grupy po GrNo1
            return groupsDB.getGroupsByGrNo1(GrNo1, farm)[0];
        }
        if (!!AnmGrp) {
            // możliwość szukania grupy po AnmGrp
            return groupsDB.getGroupByAnmGrp(AnmGrp, farm);
        }
        return animalsDB.getAnimalById(this.getAnimalID(), {
            findDeleted: true,
            joinEvents: false,
        });
    }

    getAnimalID = () => {
        const {object, id} = this.props;
        if (id) return get(object, id);
        return object?.AnmID || object?.anmid || object?.animal?.AnmID;
    };

    showSelectedItemDocument = (e) => {
        e && e.stopPropagation();
        const {selectedDocumentItem} = this.state;
        const {farm, onClick, addFarmID, goBack} = this.props;
        // na razie nie bylo potrzeby wyswietlania grupy bo nie mamy tego typu raportow/list kontrolnych
        if (!!selectedDocumentItem) {
            console.log(selectedDocumentItem);
            let selectedItemData = {item: null, id: null, isGroup: false};
            if (selectedDocumentItem.AnmGrp) {
                selectedItemData = {
                    item: selectedDocumentItem,
                    id: selectedDocumentItem.AnmGrp,
                    isGroup: true,
                };
            } else {
                const showGroup =
                    [AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(
                        selectedDocumentItem.AnimalKind
                    ) &&
                    !(selectedDocumentItem.RFID || selectedDocumentItem.Tagged);
                if (showGroup) {
                    const group = groupsDB.getGroupWithAnimal(
                        selectedDocumentItem.AnmID,
                        farm
                    )[0];
                    if (group) {
                        selectedItemData.item = group;
                        selectedItemData.id = group.AnmGrp;
                        selectedItemData.isGroup = true;
                    }
                } else {
                    selectedItemData.item = selectedDocumentItem;
                    selectedItemData.id = selectedDocumentItem.AnmID;
                }
            }
            if (selectedItemData.item) {
                // dispatch(changeSelectedItem(selectedItemData.item));
                handleAnimalInventoryRowClick(
                    selectedItemData,
                    {
                        extraData: addFarmID
                            ? {FarmID: selectedItemData.item.FarmID}
                            : {},
                    },
                    {goBack}
                );
                onClick && onClick();
            }
        }
    };

    getAnimalNumber(selectedDocumentItem) {
        const {t, farm, showGroup, groupPrefix} = this.props;
        if (
            !selectedDocumentItem.RFID &&
            !selectedDocumentItem.Tagged &&
            !getManageSubgroups() &&
            [AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(
                selectedDocumentItem.AnimalKind
            )
        ) {
            let group = null;
            if (showGroup)
                group = groupsDB.getGroupWithAnimal(
                    selectedDocumentItem.AnmID,
                    farm
                )[0];
            return group
                ? (groupPrefix ? t("group") + " " : "") + group.GrNo1
                : formatLocationName(selectedDocumentItem.PlcmntID, {
                      nameDeep: 3,
                  });
        }
        return selectedDocumentItem.AnmNo1;
    }

    render() {
        const {value, children, t, additionalClass, showRFID, groupPrefix} =
            this.props;
        const {selectedDocumentItem} = this.state;
        if (isNil(selectedDocumentItem)) return value || "";
        if (isMobile()) {
            // poprawka, bo na mobilce wyswietlalo ID zamiast numeru zwierzecia przy przekazanym tylko AnmID
            // renderowane children jako pierwsze, zeby zachowac komponent, cala reszta ifow nie powinna zmienic dzialania apki
            const id = selectedDocumentItem.AnmID || selectedDocumentItem.GrNo1;
            if (children && children !== id) {
                return (
                    <div onClick={this.showSelectedItemDocument}>
                        {children}
                    </div>
                );
            }
            if (id === value) {
                if (selectedDocumentItem.AnmGrp) {
                    return (
                        <div onClick={this.showSelectedItemDocument}>
                            {groupPrefix ? t("group") : ""}{" "}
                            {selectedDocumentItem.GrNo1}
                        </div>
                    );
                }
                return (
                    <div onClick={this.showSelectedItemDocument}>
                        {showRFID && selectedDocumentItem.RFID
                            ? selectedDocumentItem.RFID
                            : this.getAnimalNumber(selectedDocumentItem)}
                    </div>
                );
            }
            return <div onClick={this.showSelectedItemDocument}>{value}</div>;
        }
        if (selectedDocumentItem.AnmGrp) {
            return (
                <ALink
                    uppercase={false}
                    onClick={this.showSelectedItemDocument}>
                    {groupPrefix ? t("group") + " " : ""}{" "}
                    {selectedDocumentItem.GrNo1}
                </ALink>
            );
        }
        if (typeof selectedDocumentItem === "object")
            return (
                <ALink
                    uppercase={false}
                    additionalClass={additionalClass}
                    onClick={this.showSelectedItemDocument}>
                    {showRFID && selectedDocumentItem.RFID
                        ? selectedDocumentItem.RFID
                        : this.getAnimalNumber(selectedDocumentItem)}
                </ALink>
            );
        return (
            <ALink uppercase={false} onClick={this.showSelectedItemDocument}>
                {value}
            </ALink>
        );
    }
}

AnimalReferenceDocumentModalGrid.propTypes = {
    id: PropTypes.string,
    addFarmID: PropTypes.bool,
    goBack: PropTypes.bool,
    groupPrefix: PropTypes.bool,
};

AnimalReferenceDocumentModalGrid.defaultProps = {
    addFarmID: false,
    goBack: false,
    groupPrefix: true,
};

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(AnimalReferenceDocumentModalGrid);
