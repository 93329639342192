import {clone, isEmpty, isNil, memoize, uniqBy} from "lodash";
import DevType from "@wesstron/utils/Api/constants/devTypes";
import {
    getModificationTime,
    insertInto,
    setModificationTime,
} from "../utils/LokiUtils";
import {
    canAccessDevice,
    filterDevicesListByRoles,
} from "../utils/NewRolesUtils";
import buildingsDB from "./buildingsDB";
import lokiDB from "./lokiDB";

class Devices {
    constructor() {
        this.getGateway = memoize(this.getGateway, (device) =>
            device
                ? (device.ParentID ?? `${device.DevID}_${device.DtaModTime}`)
                : "NoDevice"
        );
    }

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @param id FarmID
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */
    getModificationTime(id) {
        return getModificationTime("devices", "FarmID", id);
    }

    clearCache() {
        this.getGateway.cache.clear();
    }

    getGateway(device) {
        if (device) {
            if (
                device.DevType === DevType.GATEWAY ||
                [device.ParentID, device.GatewayID].includes(device.DevID)
            ) {
                return device;
            }
            let ParentID = device.ParentID;
            if (ParentID) {
                let parent = lokiDB.devices.findOne({
                    DtaDltTime: {$type: "undefined"},
                    DevID: ParentID,
                });
                return this.getGateway(parent);
            }
        }
        return null;
    }

    /**
     * Metoda przyjmuje obiekt urzadzenia i znajduje GatewayID na podstaiwe parentow
     * @param device
     * @returns {*}
     */
    getDeviceWithGateway(device) {
        if (!device) return undefined;
        let dev = clone(device);
        let gateway = this.getGateway(dev);
        if (gateway && gateway.DevID) {
            dev.GatewayID = gateway.DevID;
        }
        return dev;
    }

    insertIntoDevices(values) {
        insertInto(values, lokiDB.devices, "DevID");
        setModificationTime(
            "devices",
            values[values.length - 1].DtaModTime,
            "FarmID",
            values[values.length - 1].FarmID
        );
        this.clearCache();
    }

    getDeviceByID(id) {
        let device = this.getDeviceWithGateway(
            lokiDB.devices.findOne({DevID: id})
        );
        if (canAccessDevice(device)) return device;
        return null;
    }

    getDevices(FarmID) {
        try {
            return filterDevicesListByRoles(
                lokiDB.devices
                    .find({
                        FarmID,
                        DtaDltTime: {$type: "undefined"},
                    })
                    .map((dev) => this.getDeviceWithGateway(dev))
            );
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Gets devices in passed placement and its children
     * @param placement
     * @param initDevs
     * @param showDevicesInChildren
     * @param allDevs
     * @returns {(Bridge|CageTwoway|Climate|DispenserWST|DispenserNRF|*)[]}
     */
    getDevicesInPlcmnt(
        placement,
        {
            initDevs = [],
            showDevicesInChildren = true,
            allDevs = lokiDB.devices.find({
                DtaDltTime: {$type: "undefined"},
                PlcmntID: {$type: "array"},
            }),
        } = {}
    ) {
        //keys with children every location has different children
        let keys = ["Buildings", "Sectors", "Chambers", "Boxes"];
        let devs = initDevs;
        if (placement) {
            let id =
                placement.BgID ||
                placement.SID ||
                placement.CID ||
                placement.BID;
            let devsInPlcmnts = allDevs.filter(
                (item) =>
                    item.PlcmntID.filter((plcmnt) => plcmnt.PlcmntID === id)
                        .length > 0
            );
            devs = [...devs, ...devsInPlcmnts];
            if (showDevicesInChildren) {
                for (let key of keys) {
                    if (placement[key]) {
                        for (let anotherPlacement of placement[key]) {
                            devs = this.getDevicesInPlcmnt(anotherPlacement, {
                                initDevs: devs,
                                showDevicesInChildren,
                                allDevs,
                            });
                        }
                    }
                }
            }
        }
        return filterDevicesListByRoles(
            uniqBy(devs, (o) => o.DevID).map((item) =>
                this.getDeviceWithGateway(item)
            )
        );
    }

    /**
     * Gets devices by passed placementID and its children
     * @param placementID
     * @param showDevicesInChildren
     * @returns {(Bridge|CageTwoway|Climate|DispenserWST|DispenserNRF|*)[]}
     */
    getDevicesInPlcmntID(placementID, {showDevicesInChildren = true} = {}) {
        if (isEmpty(placementID) || isNil(placementID)) return [];
        return this.getDevicesInPlcmnt(
            buildingsDB.getLocationByID(placementID),
            {showDevicesInChildren}
        );
    }

    getDevicesByType(FarmID, devType) {
        return filterDevicesListByRoles(
            lokiDB.devices
                .find({
                    FarmID,
                    DevType: devType,
                    DtaDltTime: {$type: "undefined"},
                })
                .map((dev) => this.getDeviceWithGateway(dev))
        );
    }

    /**
     * Gets device by passed attributes
     * @param attributes
     * @returns {*}
     */
    getDevicesByAttributes(attributes = {}) {
        try {
            return filterDevicesListByRoles(
                lokiDB.devices
                    .find({DtaDltTime: {$type: "undefined"}, ...attributes})
                    .map((dev) => this.getDeviceWithGateway(dev))
            );
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getDevicesWithParentID(parentID) {
        try {
            return filterDevicesListByRoles(
                lokiDB.devices
                    .find({
                        DtaDltTime: {$type: "undefined"},
                        ParentID: parentID,
                    })
                    .map((dev) => this.getDeviceWithGateway(dev))
            );
        } catch (e) {
            return [];
        }
    }

    /**
     * Gets device by passed name
     * @param name
     * @returns {*}
     */
    getDevicesWithName(farmID, name) {
        try {
            return filterDevicesListByRoles(
                lokiDB.devices.find({
                    FarmID: farmID,
                    DtaDltTime: {$type: "undefined"},
                    Name: name,
                })
            );
        } catch (e) {
            return [];
        }
    }

    getDevicesWithAddress(farmID, address) {
        try {
            return filterDevicesListByRoles(
                lokiDB.devices
                    .find({
                        FarmID: farmID,
                        DtaDltTime: {$type: "undefined"},
                        Address: Array.isArray(address)
                            ? {$in: address}
                            : address,
                    })
                    .map((dev) => this.getDeviceWithGateway(dev))
            );
        } catch (e) {
            return [];
        }
    }

    getAllDevices() {
        try {
            return filterDevicesListByRoles(
                lokiDB.devices
                    .find({DtaDltTime: {$type: "undefined"}})
                    .map((dev) => this.getDeviceWithGateway(dev))
            );
        } catch (e) {
            return [];
        }
    }

    getBridgesWithCOM(FarmID, COM) {
        const bridges = this.getDevicesByType(FarmID, DevType.BRIDGE);
        return bridges.filter((item) => item.Settings?.RS422?.COM === COM);
    }

    getDevicesByDevTypeAndParent(FarmID, DevType, ParentID) {
        try {
            return filterDevicesListByRoles(
                lokiDB.devices
                    .find({
                        FarmID,
                        DevType,
                        ParentID,
                        DtaDltTime: {$type: "undefined"},
                    })
                    .map((dev) => this.getDeviceWithGateway(dev))
            );
        } catch (e) {
            return [];
        }
    }
}

const devicesDB = new Devices();
export default devicesDB;
