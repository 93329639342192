import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {get, isNil} from "lodash";
import moment from "moment";
import React from "react";
import sift from "sift";
import AnimalReferenceDocumentModalGrid from "../components/grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import {controlLists, controlListsPeriods} from "../constans/controlLists";
import {USG_STATE} from "../constans/eventTypes";
import {FilterTypes} from "../constans/filter";
import {UnitTypes} from "../constans/unitTypes";
import i18n from "../i18n";
import store from "../store/store";
import {HeatsComponent} from "../views/control-lists-view/control-lists/heats/HeatsComponent";
import RepeatedInseminationControlListDiffDays from "../views/control-lists-view/control-lists/repeated-inseminations/RepeatedInseminationControlListDiffDays";
import {IdleDaysHeaderComponent} from "../views/new-reports-view/reports/IdleDaysReport";
import {getSectorTypes} from "./BuildingUtils";
import {formatLocationName} from "./global-formatters/formatLocationName";
import {
    getDaysForRepeatedInsemination,
    getTimeFromInseminationToPartuition,
} from "./SettingsUtils";
import {sortAsString} from "./SortUtils";
import {convertWeightUnitTo} from "./UnitUtils";

export function getDatesForList(list, ignoreNotCompleted = false) {
    const {
        WData: {
            Period,
            Day,
            MonthDay,
            StartDay,
            EndDay,
            showTasksNotCompletedOnTime,
            Month,
            Week,
        },
    } = list;
    let dates = {
        start: moment().startOf("day"),
    };
    if (showTasksNotCompletedOnTime) {
        dates.fromBeginning = true;
        if (!ignoreNotCompleted) {
            //opcja pokazywania wszystkich nieprawidłowości z przeszłości
            dates.start = moment()
                .set("year", 1920)
                .set("month", 4)
                .set("date", 18);
        }
    }

    if (Period === controlListsPeriods.X_DAYS) {
        dates.end = moment()
            .endOf("day")
            .add(Day - 1, "days");
        return dates;
    }
    if (Period === controlListsPeriods.END_OF_MONTH) {
        dates.end = moment().endOf("month");
        return dates;
    }
    if (Period === controlListsPeriods.TO_DAY_IN_MONTH) {
        if (ignoreNotCompleted || !showTasksNotCompletedOnTime)
            dates.start = moment().startOf("month");
        dates.end = moment()
            .startOf("month")
            .endOf("day")
            .add(Math.min(MonthDay - 1, moment().daysInMonth()), "d");
        return dates;
    }
    if (Period === controlListsPeriods.RANGE) {
        if (ignoreNotCompleted || !showTasksNotCompletedOnTime)
            dates.start = moment(StartDay)
                .subtract(moment(StartDay).utcOffset(), "m")
                .startOf("day");
        dates.end = moment(EndDay)
            .subtract(moment(EndDay).utcOffset(), "m")
            .endOf("day");
        return dates;
    }
    if (Period === controlListsPeriods.CURRENT_MONTH) {
        if (ignoreNotCompleted || !showTasksNotCompletedOnTime)
            dates.start = moment().startOf("month");
        dates.end = moment().endOf("month");
        return dates;
    }
    if (Period === controlListsPeriods.MONTH) {
        dates.start = moment().month(Month).startOf("month");
        dates.end = moment().month(Month).endOf("month");
        return dates;
    }
    if (Period === controlListsPeriods.WEEK) {
        dates.start = moment()
            .week(Week - 1)
            .startOf("week");
        dates.end = moment()
            .week(Week - 1)
            .endOf("week");
        return dates;
    }
    if (Period === controlListsPeriods.X_DAYS_BEFORE) {
        dates.start = moment().subtract(Day, "days").startOf("day");
        dates.end = moment().endOf("day");
        return dates;
    }
    return null;
}

/***************************************************************
 * Metody liczące dane od list kontrolnych
 * update 2024: sprawdż `utils/control-list-data`
 ***************************************************************/

export function getDataForanimal(
    animal,
    animalEvents,
    dates,
    day,
    eventTypeToCheck
) {
    let timeFromInseminationToParturition =
        getTimeFromInseminationToPartuition();
    let inseminations = animalEvents.filter((item) => {
        if (item.EvCode !== EventTypes.INSEMINATION) return false;
        let eventTime = moment(item.EvTime).add(day, "days").toDate().getTime();
        return (
            eventTime > dates.start.toDate().getTime() &&
            eventTime < dates.end.toDate().getTime()
        );
    });
    // zapisanie danych
    let insemination = inseminations[0];
    let daysForRepeatedInsemination = getDaysForRepeatedInsemination();
    let repeats = 0;
    for (let ins of inseminations) {
        if (
            moment(ins.EvTime).diff(insemination.EvTime, "days") >
            daysForRepeatedInsemination
        ) {
            insemination = ins;
            repeats++;
        }
    }
    if (insemination) {
        let inseminationTime = moment.utc(insemination.EvTime).startOf("day");
        const expectedDate = inseminationTime.clone().add(day, "days");
        if (
            animalEvents.some(
                (event) =>
                    [
                        EventTypes.NO_PREGNANCY,
                        EventTypes.FALL,
                        EventTypes.SELL,
                    ].includes(event.EvCode) &&
                    event.EvTime > +inseminationTime &&
                    event.EvTime < +expectedDate
            )
        )
            return null;
        let hadRequiredEvent = !!animalEvents.find(
            (event) =>
                event.EvCode === eventTypeToCheck &&
                event.EvTime > insemination.EvTime &&
                event.EvTime < new Date().getTime()
        );
        if (!hadRequiredEvent) {
            return {
                AnmID: animal.AnmID,
                AnmNo1: animal.AnmNo1,
                PlcmntID: animal.PlcmntID,
                InseminationTime: insemination.EvTime,
                date: expectedDate,
                parturitionDate: inseminationTime
                    .clone()
                    .add(timeFromInseminationToParturition, "days"),
                pregnancyDay:
                    moment.utc().startOf("day").diff(inseminationTime, "days") +
                    1,
                repeatedInsemination: repeats,
            };
        }
    }
}

export const getActiveNipples = (
    animal,
    events,
    date = new Date().getTime()
) => {
    let evs = events
        .filter(
            sift({
                EvCode: EventTypes.ACTIVE_NIPPLES,
                EvTime: {$lte: date},
            })
        )
        .sort((a, b) => b.EvTime - a.EvTime);
    let event = evs[0];
    console.log(event);
    if (event) {
        return event.EvData.Nipples;
    }
    if (isNil(animal?.ActiveNipples)) return animal.ActiveNipples;
    return null;
};

/***************************************************************
 * Formatery danych
 ***************************************************************/

export const animalLocationValueFormatter = (value) => {
    return formatLocationName(value);
};

export const formatDate = (value) => {
    if (!value) return null;
    if (value.start) {
        return `${moment(value.start).format("L")} - ${moment(value.end).format(
            "L"
        )}`;
    }
    return moment(value).format("L");
};

export const formatDateUTC = (value) => {
    if (!value) return null;
    return moment.utc(value).format("L");
};

export const formatInseminationDate = (object) => {
    const {InseminationTime, repeatedInsemination} = object;
    if (InseminationTime) {
        let text = moment(InseminationTime).format("L");
        if (repeatedInsemination) {
            text += ` [${i18n.t("inseminationRepeat", {
                repeat: repeatedInsemination,
            })}]`;
        }
        return text;
    }
    return moment(object).format("L");
};

export const formatSectorType = (value) => {
    return getSectorTypes(i18n.t)[value - 1];
};

export const formatMedicine = (value) => {
    if (value) {
        return value.WData.Name;
    }
};

export const formatDose = (value) => {
    if (value) {
        return `${value.WData.Dose} ${value.WData.Unit}`;
    }
};

export const formatWeight = (value) => {
    if (value)
        return convertWeightUnitTo(value, {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
        });
};

export const formatUSGResult = (value) => {
    if (value === USG_STATE.NEGATIVE || value === false)
        return <i className="fas fa-times error" />;
    if (value === USG_STATE.POSITIVE || value === true)
        return <i className="fas fa-check success" />;
    if (value === USG_STATE.REPEAT) return <i className="fas fa-redo" />;
    return null;
};

export const formatInseminationWeekRange = (value) => {
    if (Array.isArray(value)) {
        return `${value[0].clone().format("DD.MM")} - ${value[1]
            .clone()
            .format("L")}`;
    }
    return null;
};

export const formatAnimalAge = (value) => {
    if (!value) return null;
    return i18n.t("newSettings.breeding.manage.cycle.weeks", {count: value});
};

const fmtFixed = (value) => {
    return Number((value || 0).toFixed(1));
};

export const formatParturitionData = (value) => {
    if (!value) return null;
    const {alive, weak, dead, mummy} = value;
    return `${fmtFixed(alive)}/${fmtFixed(weak)}/${fmtFixed(dead)}/${fmtFixed(
        mummy
    )}`;
};

export const formatPercent = (value) => {
    if (!value) return null;
    return `${value.toFixed(1)}%`;
};

export const formatComment = (value = []) => {
    let state = store.getState();
    let dictionary = get(state, "dictionary.comments.WData", []);
    return value
        .map((id) => dictionary.find((item) => item.ID === id)?.Value || "")
        .filter((item) => item)
        .join(", ");
};

/***************************************************************
 * Metody pobierające headery
 ***************************************************************/

export const emptyHeader = {
    name: "",
    headerClassName: "d-none empty",
    itemClassName: "d-none empty",
    field: "null",
};

export const checkHeader = {
    name: "\u2713",
    headerClassName: "d-none",
    itemClassName: "d-none",
    field: "null",
};

export function getInseminationHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            filterType: FilterTypes.STRING,
            customSort: sortAsString,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("inseminationDate"),
            field: "InseminationTime",
            valueFormatter: formatDateUTC,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("examinationDate"),
            field: "date",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("usg"),
            field: "usg.EvData.Pregnant",
            valueFormatter: formatUSGResult,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ];
}

export function getConditionHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("inseminationDate"),
            field: "InseminationTime",
            valueFormatter: formatDateUTC,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("pregnancyDay"),
            field: "pregnancyDay",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("parturitionDate"),
            field: "parturitionDate",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("examinationDate"),
            field: "date",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ];
}

export function getUSGHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("inseminationDate"),
            field: "InseminationTime",
            valueFormatter: formatDateUTC,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("pregnancyDay"),
            field: "pregnancyDay",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("parturitionDate"),
            field: "parturitionDate",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("usgDate"),
            field: "date",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ];
}

export function getSowsToInseminationHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("idle"),
            field: "sterilleDays",
            filterType: FilterTypes.NUMBER,
        },
    ];
}

export function getSowsToTransferHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("currentSector"),
            field: "currentSector",
            valueFormatter: formatSectorType,
        },
        {
            name: i18n.t("nextSector"),
            field: "nextSector",
            valueFormatter: formatSectorType,
        },
        {
            name: i18n.t("transferDate"),
            field: "transferDate",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ];
}

export function getPlannedParturitionHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("inseminationDate"),
            field: "InseminationTime",
            valueFormatter: formatInseminationDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("pregnancyDay"),
            field: "pregnancyDay",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("parturitionDate"),
            field: "parturitionDate",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ];
}

export function getTreatmentHeaders(showAnmNo1) {
    return [
        showAnmNo1 && {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("weight"),
            field: "weight",
            valueFormatter: formatWeight,
        },
        {
            name: i18n.t("lastWeighting"),
            field: "LastWeightingTime",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("medicine"),
            field: "medicine",
            valueFormatter: formatMedicine,
        },
        {
            name: i18n.t("dosage"),
            field: "medicine",
            valueFormatter: formatDose,
        },
        {
            name: i18n.t("date"),
            field: "date",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ].filter((item) => !!item);
}

export function getFosteringHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("parturitionDate"),
            field: "ParturitionTime",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("alivePiglets"),
            field: "alive",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("aliveGilts"),
            field: "gilts",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("activeNipples"),
            field: "nipples",
            filterType: FilterTypes.NUMBER,
        },
    ];
}

export function getRepeatedInseminationHeaders(list) {
    let headers = [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name:
                list?.WData?.Cycles === "All"
                    ? i18n.t("events.insertionEvent.cycles")
                    : i18n.t("cycle"),
            field: "cycleNumber",
            filterType: FilterTypes.NUMBER,
        },
        {
            name:
                list?.WData?.Cycles === "All"
                    ? i18n.t("allRepeats")
                    : i18n.t("planTypes.numberOfRepetitions"),
            field: "repeats",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("lastInseminationDate"),
            field: "lastInseminationTime",
            valueFormatter: formatDateUTC,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ];
    if (list?.WData?.Cycles === "All") {
        headers.push({
            name: i18n.t("maxRepeatsInCycle"),
            field: "max",
        });
    } else {
        headers.push({
            name: i18n.t("amountBetweenInseminationAndRepeat"),
            field: "diff",
            component: <RepeatedInseminationControlListDiffDays />,
        });
    }
    return headers;
}

export function getRenovationSowsToInseminationHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("grid.whenInseminate"),
            field: "InseminationWeek",
            valueFormatter: formatInseminationWeekRange,
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("newSettings.breeding.graftingProgram.animalAge"),
            field: "AnimalAge",
            filterType: FilterTypes.NUMBER,
            valueFormatter: formatAnimalAge,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
    ];
}

export function getHerdStatusHeaders() {
    return [
        {
            name: i18n.t("herdNumber"),
            field: "herd",
            filterType: FilterTypes.STRING,
        },
        {
            name: i18n.t("amountOfSows"),
            field: "sows",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("amountOfRenovationSows"),
            field: "sows0Cycle",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("amountOfPiglets"),
            field: "piglets",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("amountOfPigs"),
            field: "pigs",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("amountOfPorkers"),
            field: "porkers",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("amountOfBoars"),
            field: "boars",
            filterType: FilterTypes.NUMBER,
        },
    ];
}

export function getSowSelectionHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("grid.idleDays"),
            field: "idleDays",
            filterType: FilterTypes.NUMBER,
            headerComponent: (
                <IdleDaysHeaderComponent
                    name={i18n.t("grid.idleDays")}
                    tooltipContent={i18n.t("newReports.idle.cycleColumnInfo")}
                />
            ),
        },
        // {
        //     name: i18n.t("motherDays"),
        //     field: "motherDays",
        //     filterType: FilterTypes.NUMBER
        // },
        {
            name: i18n.t("planTypes.parturitionEffectiveness"),
            field: "parturitionEfficiency",
            filterType: FilterTypes.NUMBER,
            valueFormatter: formatPercent,
        },
        {
            name: i18n.t("averageSepareted"),
            field: "averageSepareted",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("separetedLastCycle"),
            field: "separetedLastCycle",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("averageHealthyBirth"),
            field: "averageAlive",
            filterType: FilterTypes.NUMBER,
            valueFormatter: formatParturitionData,
        },
        {
            name: i18n.t("numberOfHealthyInLastCycle"),
            field: "averageAliveLastCycle",
            filterType: FilterTypes.NUMBER,
            valueFormatter: formatParturitionData,
        },
        {
            name: i18n.t("index"),
            field: "index",
            filterType: FilterTypes.NUMBER,
        },
    ];
}

export function getSowSeparationHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("grid.dateSeparation"),
            field: "plannedSeparation",
            valueFormatter: formatDate,
            filterType: FilterTypes.DATE,
            disableValueFormatterSort: true,
            disableValueFormatterFilter: true,
        },
        {
            name: i18n.t("pigletsAmount"),
            field: "balance",
            filterType: FilterTypes.NUMBER,
        },
    ];
}

export function getHeatsHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("heats"),
            field: "heats",
            component: (props) => <HeatsComponent {...props} />,
        },
    ];
}

export function getPigletsTreatmentHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("location"),
            field: "PlcmntID",
            filterType: FilterTypes.LOCATION,
            disableValueFormatterFilter: true,
            valueFormatter: animalLocationValueFormatter,
        },
        {
            name: i18n.t("pigletsAmount"),
            field: "balance",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("castration"),
            field: "castration",
            valueFormatter: formatDate,
        },
        {
            name: i18n.t("tails"),
            field: "tails",
            valueFormatter: formatDate,
        },
        {
            name: i18n.t("teeth"),
            field: "teeth",
            valueFormatter: formatDate,
        },
        {
            name: i18n.t("weighting"),
            field: "weighting",
            valueFormatter: formatDate,
        },
    ];
}

export function getPigBalanceHeaders() {
    return [
        {
            name: i18n.t("animalNumber"),
            field: "AnmNo1",
            customSort: sortAsString,
            filterType: FilterTypes.STRING,
            component: (props) => (
                <AnimalReferenceDocumentModalGrid {...props} />
            ),
        },
        {
            name: i18n.t("grid.cycleNumber"),
            field: "Cycle",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("alive"),
            field: "HealthyCnt",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("printSelectedAnimalsModal.falls"),
            field: "FallCnt",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("addedPiglets"),
            field: "MommyCnt",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("takenPiglets"),
            field: "SeparationToMommyCnt",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("newReports.headers.separation"),
            field: "SeparationCnt",
            filterType: FilterTypes.NUMBER,
        },
        {
            name: i18n.t("summary"),
            field: "Balance",
            filterType: FilterTypes.NUMBER,
        },
    ];
}

export const getControlListName = (value) => {
    switch (value) {
        case controlLists.INSEMINATIONS:
            return i18n.t("controlLists.mainView.sowsForRepeatedInsemination");
        case controlLists.CONDITION:
            return i18n.t("condition");
        case controlLists.USG:
            return i18n.t("usgTest");
        case controlLists.SOWS_TO_INSEMINATION:
            return i18n.t("controlLists.mainView.sowsToInseminationTitle");
        case controlLists.SOWS_TO_TRANSFER:
            return i18n.t("controlLists.mainView.sowsToTransferTitle");
        case controlLists.PLANNED_PARTURITIONS:
            return i18n.t("controlLists.mainView.plannedParturitionsTitle");
        case controlLists.SOWS_TO_GRAFTING:
            return i18n.t("controlLists.mainView.sowsToGraftingTitle");
        case controlLists.BOARS_TO_GRAFRING:
            return i18n.t("controlLists.mainView.boarsToGraftingTitle");
        case controlLists.PORKER_TO_GRAFTING:
            return i18n.t("controlLists.mainView.porkerToGraftingTitle");
        case controlLists.PIGLET_TO_GRAFTING:
            return i18n.t("controlLists.mainView.pigletToGraftingTitle");
        case controlLists.RENOVATION_SOW_TO_GRAFTING:
            return i18n.t(
                "controlLists.mainView.renovationSowsToGraftingTitle"
            );
        case controlLists.SOWS_FORAGE:
            return i18n.t("controlLists.mainView.sowsForageTitle");
        case controlLists.BOARS_FORAGE:
            return i18n.t("controlLists.mainView.boarsForageTitle");
        case controlLists.PORKER_FORAGE:
            return i18n.t("controlLists.mainView.porkerForageTitle");
        case controlLists.PIGLET_FORAGE:
            return i18n.t("controlLists.mainView.pigletForageTitle");
        case controlLists.RENOVATION_SOW_FORAGE:
            return i18n.t("controlLists.mainView.renovationSowsForageTitle");
        case controlLists.SOWS_DOSATRON:
            return i18n.t("controlLists.mainView.sowsDosatronTitle");
        case controlLists.BOARS_DOSATRON:
            return i18n.t("controlLists.mainView.boarsDosatronTitle");
        case controlLists.PORKER_DOSATRON:
            return i18n.t("controlLists.mainView.porkerDosatronTitle");
        case controlLists.PIGLET_DOSATRON:
            return i18n.t("controlLists.mainView.pigletDosatronTitle");
        case controlLists.RENOVATION_SOW_DOSATRON:
            return i18n.t("controlLists.mainView.renowationSowsDosatronTitle");
        case controlLists.SOWS_STIMULATOR:
            return i18n.t("controlLists.mainView.sowsStimulatorTitle");
        case controlLists.FOSTERING:
            return i18n.t("controlLists.mainView.fosteringTitle");
        case controlLists.REPEATED_INSEMINATIONS:
            return i18n.t("controlLists.mainView.repeatedInseminationsTitle");
        case controlLists.RENOVATION_SOW_TO_INSEMINATION:
            return i18n.t(
                "controlLists.mainView.renovationSowsToInseminationTitle"
            );
        case controlLists.ABNORMALITIES:
            return i18n.t("grid.abnormalities");
        case controlLists.HERD_STATUS:
            return i18n.t("herd");
        case controlLists.SOW_SELECTION:
            return i18n.t("sowSelection");
        case controlLists.SOW_SEPARATION:
            return i18n.t("plannedSeparations");
        case controlLists.HEATS:
            return i18n.t("heats");
        case controlLists.PIGLETS_TREATMENT:
            return i18n.t("events.pigletsTreatment");
        case controlLists.PIG_BALANCE:
            return i18n.t("pigBalance");
        case controlLists.NON_EATING_NUTRI_GROUP:
            return i18n.t("nonEatingNutriGroup");
        case controlLists.NON_EATING_STANDS:
            return i18n.t("nonEatingStandings");
        default:
            return "";
    }
};

export const getControlListDescription = (value) => {
    switch (value.WordID) {
        case controlLists.INSEMINATIONS:
            let inseminationTime = value.WData.InseminationTime;
            if (Array.isArray(inseminationTime)) {
                return i18n.t(
                    "controlLists.mainView.inseminationDescriptionArray",
                    {
                        count: inseminationTime[1],
                        day1: inseminationTime[0],
                    }
                );
            }
            return i18n.t("controlLists.mainView.inseminationDescription", {
                count: inseminationTime,
            });
        case controlLists.CONDITION:
            let conditionTimes = value.WData.ConditionDays;
            return i18n.t("controlLists.mainView.conditionsDescription", {
                amount: conditionTimes.join(", "),
            });
        case controlLists.USG:
            let usgTimes = value.WData.USGDays;
            return i18n.t("controlLists.mainView.usgDescription", {
                amount: usgTimes.join(", "),
            });
        case controlLists.SOWS_TO_INSEMINATION:
            return i18n.t(
                "controlLists.mainView.sowsToInseminationDescription"
            );
        case controlLists.SOWS_TO_TRANSFER:
            return i18n.t("controlLists.mainView.sowsToTransferDescription");
        case controlLists.PLANNED_PARTURITIONS:
            return i18n.t(
                "controlLists.mainView.plannedParturitionsDescriptions"
            );
        case controlLists.SOWS_TO_GRAFTING:
            return i18n.t("controlLists.mainView.sowsToGraftingDescription");
        case controlLists.BOARS_TO_GRAFRING:
            return i18n.t("controlLists.mainView.boarsToGraftingDescription");
        case controlLists.PORKER_TO_GRAFTING:
            return i18n.t("controlLists.mainView.porkerToGraftingDescription");
        case controlLists.PIGLET_TO_GRAFTING:
            return i18n.t("controlLists.mainView.pigletToGraftingDescription");
        case controlLists.RENOVATION_SOW_TO_GRAFTING:
            return i18n.t(
                "controlLists.mainView.renovationSowsToGraftingDescription"
            );
        case controlLists.SOWS_FORAGE:
            return i18n.t("controlLists.mainView.sowsForageDescription");
        case controlLists.BOARS_FORAGE:
            return i18n.t("controlLists.mainView.boarsForageDescription");
        case controlLists.PORKER_FORAGE:
            return i18n.t("controlLists.mainView.porkerForageDescription");
        case controlLists.PIGLET_FORAGE:
            return i18n.t("controlLists.mainView.pigletForageDescription");
        case controlLists.RENOVATION_SOW_FORAGE:
            return i18n.t(
                "controlLists.mainView.renovationSowsForageDescription"
            );
        case controlLists.SOWS_DOSATRON:
            return i18n.t("controlLists.mainView.sowsDosatronDescription");
        case controlLists.BOARS_DOSATRON:
            return i18n.t("controlLists.mainView.boarsDosatronDescription");
        case controlLists.PORKER_DOSATRON:
            return i18n.t("controlLists.mainView.porkerDosatronDescription");
        case controlLists.PIGLET_DOSATRON:
            return i18n.t("controlLists.mainView.pigletDosatronDescription");
        case controlLists.RENOVATION_SOW_DOSATRON:
            return i18n.t(
                "controlLists.mainView.renovationSowsDosatronDescription"
            );
        case controlLists.SOWS_STIMULATOR:
            return i18n.t("controlLists.mainView.sowsStimulatorDescription");
        case controlLists.FOSTERING:
            return i18n.t("controlLists.mainView.fosteringDescription");
        case controlLists.REPEATED_INSEMINATIONS:
            return i18n.t(
                "controlLists.mainView.repeatedInseminationDescription"
            );
        case controlLists.RENOVATION_SOW_TO_INSEMINATION:
            return i18n.t(
                "controlLists.mainView.renovationSowsToInseminationDescription"
            );
        case controlLists.ABNORMALITIES:
            return i18n.t("abnormalitiesControlListDescription");
        case controlLists.HERD_STATUS:
            return i18n.t("herdStatusControlListDescription");
        case controlLists.SOW_SELECTION:
            return i18n.t("sowSelectionDescription");
        case controlLists.SOW_SEPARATION:
            return i18n.t("sowSeparationDescription");
        case controlLists.HEATS:
            return i18n.t("heatsControlListDescription");
        case controlLists.PIGLETS_TREATMENT:
            return i18n.t("pigletsTreatmentDescription");
        case controlLists.PIG_BALANCE:
            return i18n.t("unsettledCycles");
        case controlLists.NON_EATING_NUTRI_GROUP:
            return i18n.t("nonEatingNutriGroupDescription");
        case controlLists.NON_EATING_STANDS:
            return i18n.t("nonEatingStandingsDescription");
        default:
            return "";
    }
};
