import * as AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {get, isString, last, memoize} from "lodash";
import {createSelector} from "reselect";
import animalsDB from "../database/animalsDB";
import {preEvents} from "../utils/AnimalDocumentsUtils";
import {formatAnimalName, isGiltForbidden} from "../utils/AnimalsUtils";
import {makeGetManageBuildingsList} from "./buildingsSelector";
import {getAllEventsForFarm} from "./eventsSelectors";
import {getAllGroups, getManageSubgroups} from "./groupSelector";
import utilSelectors from "./utilSelectors";

const getBuildingListSelector = makeGetManageBuildingsList();
const buildingListProps = {showDeleted: true};

const getBuildingList = (state) =>
    getBuildingListSelector(state, buildingListProps);

const formatterSelector = (_groups, _manageSubroups, _bulildingList) => {
    return (animal, options) =>
        formatAnimalName(animal, {
            groups: _groups,
            manageSubgroups: _manageSubroups,
            buildingList: _bulildingList,
            ...(!!options && options),
        });
};

export const animalFormatterSelector = createSelector(
    [getAllGroups, getManageSubgroups, getBuildingList],
    formatterSelector
);

export const makeAnimalFormatterSelector = () =>
    createSelector(
        [getAllGroups, getManageSubgroups, getBuildingList],
        formatterSelector
    );

const makeAnimalSelector = (
    showDead,
    showDeleted,
    animalsMap,
    skipSingleAnimals = true
) => {
    return createSelector(
        [
            utilSelectors.getFarmID,
            utilSelectors.getAnimalDtaModTime,
            animalFormatterSelector,
            getAllGroups,
        ],
        (farmId, animalDtaModTime, animalFormatter, groups) => {
            const animals = animalsMap ? {} : [];
            const insertAnimal = (animalObject) => {
                if (animalsMap) {
                    animals[animalObject.AnmID] = animalObject;
                } else {
                    animals.push(animalObject);
                }
            };
            const rawAnimals = animalsDB.getAllAnimals(
                farmId,
                undefined,
                showDead,
                false,
                showDeleted
            );
            for (let animal of rawAnimals) {
                if (
                    [AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(
                        animal.AnimalKind
                    )
                ) {
                    // jesli tucznik nie ma grupy to go pomijamy
                    if (skipSingleAnimals) {
                        if (animal.DtaDthTime || animal.DtaDelTime) {
                            if (
                                !groups.some(({Rmvd}) =>
                                    Rmvd.includes(animal.AnmID)
                                )
                            ) {
                                continue;
                            }
                        } else if (
                            !groups.some(({AnmIDs}) =>
                                AnmIDs.includes(animal.AnmID)
                            )
                        ) {
                            continue;
                        }
                    }
                    insertAnimal({...animal, AnmNo1: animalFormatter(animal)});
                } else {
                    insertAnimal(animal);
                }
            }
            return animals;
        }
    );
};

export const getActiveAnimals = makeAnimalSelector(false, false, false);

export const getActiveAnimalsWithoutSkip = makeAnimalSelector(
    false,
    false,
    false,
    false
);

export const getAllAnimals = makeAnimalSelector(true, true, false);

export const getAnimalsMap = makeAnimalSelector(true, true, true);

const getActiveBuildings = makeGetManageBuildingsList();

export const getAnimalCountByPlacementID = createSelector(
    [getActiveAnimals, getActiveBuildings, utilSelectors.getFarmID],
    (animals, buildings, farmId) => {
        // dictionary which translates placementId to number of animals inside
        const animalCntDict = {
            [farmId]: 0,
        };
        // add number of animals to selected placement
        const addCnt = (_placementId, _anmCnt) => {
            // if placement is not in dictionary, create one with 0 animals
            if (!animalCntDict[_placementId]) animalCntDict[_placementId] = 0;
            // add animal number
            animalCntDict[_placementId] += _anmCnt || 0;
        };
        // loop through all alive animals
        for (const animal of animals) {
            // increase farm counter
            addCnt(farmId, animal.AnmCnt);
            if (isString(animal.PlcmntID)) {
                // increase placement counter
                addCnt(animal.PlcmntID, animal.AnmCnt);
            }
        }
        // countChildren = {boolean} - include animals in placements' children
        return memoize(
            (PlcmntID, countChildren = true) => {
                // treat farm as a special case
                if (PlcmntID === farmId) return animalCntDict[PlcmntID];
                const PlcmntIDs = new Set([PlcmntID]);
                if (countChildren) {
                    // find children ids
                    const setPlcmntIDs = (_parentId) => {
                        buildings.forEach((item) => {
                            if (item.parentId === _parentId) {
                                PlcmntIDs.add(item.id);
                                setPlcmntIDs(item.id);
                            }
                        });
                    };
                    setPlcmntIDs(PlcmntID);
                }
                // merge all placements animals into one number
                return [...PlcmntIDs].reduce((animalCount, placementId) => {
                    return animalCount + (animalCntDict[placementId] || 0);
                }, 0);
            },
            (PlcmntID, countChildren) => `${PlcmntID}->${+!!countChildren}`
        );
    }
);

export const getSowsWithGilts = createSelector(
    [getAllEventsForFarm],
    (allEventsForFarm) => {
        const anmIDs = [];
        const sows = {};
        for (const event of allEventsForFarm) {
            if (
                event.EvCode !== EventTypes.PARTURITION ||
                !event.EvData?.HealthyCnt ||
                !event.EvData?.GiltsCnt
            )
                continue;
            anmIDs.push(event.AnmID);
        }
        for (const event of allEventsForFarm) {
            if (!anmIDs.includes(event.AnmID)) continue;
            if (!sows[event.AnmID]) sows[event.AnmID] = [];
            sows[event.AnmID].push(event);
        }
        for (const anmID in sows) {
            const cycles = preEvents(sows[anmID], null)?.cycleTable || [];
            const lastCycle = last(cycles);
            const lastParturition = get(
                lastCycle,
                `[${EventTypes.PARTURITION}][0]`
            );
            const insemination = get(
                lastCycle,
                `[${EventTypes.INSEMINATION}][0]`
            );
            const submitted = get(
                lastCycle,
                `[${EventTypes.TATTOO}]`,
                []
            ).reduce(
                (prev, curr) =>
                    prev + get(curr, "EvData.TattooedAnimals", []).length,
                0
            );
            const lastParturitionGilts = get(
                lastParturition,
                "EvData.GiltsCnt",
                0
            );
            if (
                !insemination ||
                lastParturitionGilts === 0 ||
                lastParturitionGilts <= submitted
            ) {
                delete sows[anmID];
            }
        }
        return sows;
    }
);

export const getSowsWithTattooedAnimals = createSelector(
    [getAllEventsForFarm],
    (allEventsForFarm = []) => {
        const anmIDs = [];
        const sows = {};
        for (const event of allEventsForFarm) {
            if (
                (event.EvCode === EventTypes.TATTOO &&
                    get(event, "EvData.TattooedAnimals", []).some(
                        (gilt) => !isGiltForbidden(gilt)
                    )) ||
                (event.EvCode === EventTypes.SEPARATION_TO_MOMMY_GILTS &&
                    !isGiltForbidden(event.EvData))
            ) {
                anmIDs.push(event.AnmID);
            }
        }
        for (const event of allEventsForFarm) {
            if (!anmIDs.includes(event.AnmID)) continue;
            if (!sows[event.AnmID]) sows[event.AnmID] = [];
            sows[event.AnmID].push(event);
        }
        return sows;
    }
);

export const getTattooedGilts = createSelector(
    [getAllEventsForFarm],
    (events) => {
        const tattooedGilts = {};
        for (const event of events) {
            if (event.EvCode === EventTypes.TATTOO) {
                for (const tattooedAnimal of get(
                    event,
                    "EvData.TattooedAnimals",
                    []
                )) {
                    if (isGiltForbidden(tattooedAnimal)) continue;
                    if (!tattooedGilts[event.AnmID])
                        tattooedGilts[event.AnmID] = [];
                    tattooedGilts[event.AnmID].push({
                        AnmNo1: tattooedAnimal.AnmNo1,
                        RFID: tattooedAnimal.RFID,
                    });
                }
            } else if (
                event.EvCode === EventTypes.SEPARATION_TO_MOMMY_GILTS &&
                !isGiltForbidden(event.EvData)
            ) {
                if (!tattooedGilts[event.AnmID])
                    tattooedGilts[event.AnmID] = [];
                tattooedGilts[event.AnmID].push({
                    AnmNo1: event.EvData.AnmNo1,
                    RFID: event.EvData.RFID,
                });
            }
        }
        return tattooedGilts;
    }
);

export const getTattooedGiltsWithRFID = createSelector(
    [getTattooedGilts],
    (gilts) => {
        const giltsWithRFID = {};
        for (const anmID in gilts) {
            for (const {AnmNo1, RFID} of gilts[anmID]) {
                if (RFID) {
                    giltsWithRFID[RFID] = {
                        AnmNo1,
                        AnimalKind: AnimalTypes.RENOVATION_SOW,
                    };
                }
            }
        }
        return giltsWithRFID;
    }
);

export const getAnimalPlcmntMap = createSelector(
    getActiveAnimals,
    (animals) => {
        const tmp = {};
        for (let animal of animals) {
            if (!tmp[animal.PlcmntID]) tmp[animal.PlcmntID] = [];
            tmp[animal.PlcmntID].push(animal);
        }
        return tmp;
    }
);
