export const controlLists = {
    INSEMINATIONS: "Inseminations",
    CONDITION: "Condition",
    USG: "USG",
    SOWS_TO_INSEMINATION: "SowsToInsemination",
    SOWS_TO_TRANSFER: "SowsToTransfer",
    PLANNED_PARTURITIONS: "PlannedParturitions",
    SOWS_TO_GRAFTING: "SowsToGrafting",
    BOARS_TO_GRAFRING: "BoarsToGrafting",
    PORKER_TO_GRAFTING: "PorkerToGrafting",
    PIGLET_TO_GRAFTING: "PigletToGrafting",
    RENOVATION_SOW_TO_GRAFTING: "RenovationSowToGrafting",
    SOWS_FORAGE: "SowsForage",
    BOARS_FORAGE: "BoarsForage",
    PORKER_FORAGE: "PorkerForage",
    PIGLET_FORAGE: "PigletForage",
    RENOVATION_SOW_FORAGE: "RenovationSowForage",
    SOWS_DOSATRON: "SowsDosatron",
    BOARS_DOSATRON: "BoarsDosatron",
    PORKER_DOSATRON: "PorkerDosatron",
    PIGLET_DOSATRON: "PigletDosatron",
    RENOVATION_SOW_DOSATRON: "RenovationSowDosatron",
    SOWS_STIMULATOR: "SowsStimulators",
    FOSTERING: "Remanent",
    REPEATED_INSEMINATIONS: "RepeatedInseminations",
    RENOVATION_SOW_TO_INSEMINATION: "RenovationSowsToInsemination",
    ABNORMALITIES: "Abnormalities",
    HERD_STATUS: "HerdStatus",
    SOW_SELECTION: "SowSelection",
    SOW_SEPARATION: "SowSeparation",
    PIGLETS_TREATMENT: "PigletTreatment",
    PIG_BALANCE: "PigBalance",
    HEATS: "HeatControlList",
    NON_EATING_NUTRI_GROUP: "NonEatingPigletNutriGroup",
    NON_EATING_STANDS: "NonEatingPigletStandings",
};

export const controlListsPeriods = {
    X_DAYS: "0",
    END_OF_MONTH: "1",
    TO_DAY_IN_MONTH: "2",
    RANGE: "3",
    CURRENT_MONTH: "4",
    MONTH: "5",
    WEEK: "6",
    X_DAYS_BEFORE: "7",
};
