import {
    get,
    isArray,
    isFunction,
    isNil,
    isNumber,
    isObject,
    set,
    isBoolean,
    isFinite,
} from "lodash";
import store from "../store/store";

export const makeStorageWrapper = (storage, name) => {
    const checkIfStorageExists = () => {
        try {
            return isFunction(storage?.removeItem);
        } catch (err) {
            return false;
        }
    };

    const ReactStorage = {
        /**
         * Zapisuje klucz wartość do storage
         * @param key
         * @param value
         * @return {boolean}
         */
        set: (key, value) => {
            if (!checkIfStorageExists()) return false;
            const valueAsString =
                typeof value === "string" ? value : JSON.stringify(value);
            storage.setItem(key, valueAsString);
            let event = document.createEvent("Event");
            event.initEvent(`${name}Change`, true, true);
            event.key = key;
            event.value = value;
            document.dispatchEvent(event);
            return true;
        },
        /**
         * Pobiera wraz z zamianią na odpowiedni typ wartość z storage
         * @param key
         * @param defaultValue
         * @return {number|boolean|any}
         */
        get: (key, defaultValue) => {
            if (!checkIfStorageExists()) return defaultValue || null;
            const rawValue = storage.getItem(key);
            let value;
            try {
                // wywala się dla string-ów które nie są boolean-ami ani wartościami liczbowymi
                value = JSON.parse(rawValue);
            } catch (e) {
                value = rawValue;
            }
            if (isNil(value)) return defaultValue; // jesli nie znajdziemy
            if (isArray(value) || isObject(value) || isBoolean(value))
                return value;
            if (["true", "false"].includes(value)) return value === "true"; // jesli jest booleanem
            if (isNumber(+value) && isFinite(+value)) return +value; // numer
            return value; // string
        },
        /**
         * Zapisuje klucz-wartość do storage dla aktualnego użytkownika
         * @param key
         * @param value
         * @return {boolean}
         */
        setPrivate: (key, value) => {
            if (!checkIfStorageExists()) return false;
            const LocalUserID = get(store.getState(), "user.user.LocalUserID");
            if (!LocalUserID) return false;
            let userObject = ReactStorage.get(`$_${LocalUserID}`, {});
            if (!isObject(userObject)) {
                userObject = {};
            }
            set(userObject, key, value);
            ReactStorage.set(`$_${LocalUserID}`, userObject);
            return true;
        },
        /**
         * Odcytuje wartość dla podanego klucza dla aktualnego użytkownika
         * @param key
         * @param defaultValue
         */
        getPrivate: (key, defaultValue) => {
            if (!checkIfStorageExists()) return defaultValue || null;
            const LocalUserID = get(store.getState(), "user.user.LocalUserID");
            if (!LocalUserID) return defaultValue;
            let userObject = ReactStorage.get(`$_${LocalUserID}`, {});
            if (!isObject(userObject)) {
                userObject = {};
            }
            return get(userObject, key, defaultValue);
        },
        /**
         * Czysci 'prywatny' storage dla każdego z użytkowników
         */
        clearPrivate: () => {
            if (!checkIfStorageExists()) return;
            Object.keys(storage).forEach((key) => {
                if (key.startsWith("$_")) {
                    storage.removeItem(key);
                }
            });
        },
        /**
         * Czyści cały storage
         */
        clear: () => {
            if (!checkIfStorageExists()) return;
            storage.clear();
        },
        /**
         * Usuniecie klucza prywatnego
         */
        deletePrivate: (key) => {
            if (!checkIfStorageExists()) return false;
            const LocalUserID = get(store.getState(), "user.user.LocalUserID");
            if (!LocalUserID) return false;
            let userObject = ReactStorage.get(`$_${LocalUserID}`, {});
            if (!isObject(userObject)) {
                userObject = {};
            }
            delete userObject[key];
            ReactStorage.set(`$_${LocalUserID}`, userObject);
        },
    };
    return ReactStorage;
};
